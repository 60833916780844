import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { SnackbarProvider } from 'notistack';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { theme } from 'config';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import { Provider } from "react-redux";
import { store } from "./redux/store";

const chains = [arbitrum, mainnet, polygon];
const projectId = process.env.REACT_APP_PROJECT_ID;

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

const App = () => (
  <SnackbarProvider autoHideDuration={2000} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
    <WagmiConfig client={wagmiClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Header />
          <Outlet />
          <Footer />
          {/* <Home></Home> */}
        </ThemeProvider>
      </Provider>
    </WagmiConfig>
    <Web3Modal
      themeVariables={{
        "--w3m-accent-color": "transparent",
        "--w3m-accent-fill-color": "#FFFFFF",
        '--w3m-button-border-radius': '50px',
        // "--w3m-background-color": "#02FFFF",
        '--w3m-z-index': '9999',
        '--w3m-overlay-background-color': 'transparent',
        '--w3m-overlay-backdrop-filter': 'none',
        '--w3m-color-overlay': 'transparent'
      }}
      defaultChain={arbitrum}
      projectId={projectId}
      ethereumClient={ethereumClient}
      explorerRecommendedWalletIds={["c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"]}
      explorerExcludedWalletIds="ALL"
    />
  </SnackbarProvider>
);

export default App;
