const theme = {
  main: '#02FFFF',
  mainDark: '#02DDDD',
  gradient: 'linear-gradient(8.48deg, #02FFFF 15.03%, rgba(2, 255, 255, 0) 92.61%)',
  black: '#000000',
  white: '#ffffff',
  gray: '#0F1314a3',
};

export default theme;
