import React, { useEffect, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import useBrowserResize from 'contexts/resize';
import logoSvg from 'assets/images/logo_2.svg';
import HambergerSvg from 'assets/images/hamberger.svg';
import CloseSvg from 'assets/images/x_white.svg';
import { Web3Button, useWeb3Modal } from '@web3modal/react';
import { useAccount , useDisconnect } from 'wagmi';
import SignPop from './components/SignPop';
import { utils } from 'ethers';
import * as S from './styles';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { setLogout } from "../../redux/userSlice.js";

const Header = () => {
  const { address , isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  const [scrolled, setScrolled] = useState(false);
  const { width } = useBrowserResize();
  const isMobile = width < 1024;
  const [showPop, setShowPop] = useState(false);
  const [ showSign, setShowSign ] = useState(false);
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const stateHandler = (e) => {
    window.console.log('这是e--', e);
  };

  useEffect(() => {
    console.log('isConnected', isConnected);
    console.log('userState', userState);
    if (!isConnected) {
      setShowSign(false)
      logoutHandler()
      return;
    }
    if (!userState.accessToken) {
      setShowSign(true)
      return;
    }
    setShowSign(false)
  },[isConnected, userState])

  useEffect(() => {
    console.log('address', address);
    console.log('userState', userState);
    if (address && userState.address && address != userState.address) {
      logoutHandler()
    }
  },[address, userState])

  useEffect(() => {
    window.addEventListener('popstate', stateHandler);
    return () => {
      window.removeEventListener('popstate', stateHandler);
    };
  });

  useEffect(() => {
    if (!isMobile && showPop) setShowPop(false);
  }, [isMobile]);

  const logoutHandler = ( ) => {
    disconnect();
    dispatch(setLogout())
  }

  const changeBackground = () => {
    if (window.scrollY >= 66 && !showPop) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleToast = () => {
    enqueueSnackbar('Coming Soon...', {
      variant: 'info'
    });
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground);
  });

  return (
    <S.Container scrolled={scrolled}>
      {showSign ? <SignPop changeShow={setShowSign} logoutHandler={logoutHandler} /> : null }
      <S.InnerContainer>
        <Link to='/'>
          <S.Logo src={logoSvg} />
        </Link>
        <S.MenuWrapper>
          {/* <S.MenuItem href="#tradeGDT">TradeGDT</S.MenuItem>
          <S.MenuItem href="#Economy">Economy</S.MenuItem>
          <S.MenuItem href="#Technology">Technology</S.MenuItem> */}
          <S.MenuItem>
            <Link to='/ai-incubator'>AI Incubator</Link>
          </S.MenuItem>
          {/* <S.MenuItem href="#Balancer">AI Balancer</S.MenuItem>
          <S.MenuItem href="#NFT">AI NFT</S.MenuItem> */}
          <S.MenuItem acitve="true" >
            {/* AI Marketplace */}
            <Link to="/ai-marketplace">AI Marketplace</Link>
          </S.MenuItem>
          <S.MenuItem onClick={() => handleToast()}>Tokenomics</S.MenuItem>
          <S.MenuItem onClick={() => handleToast()}>Whitepaper</S.MenuItem>
        </S.MenuWrapper>
        {/* <S.ConnectButton>Connect Wallet</S.ConnectButton> */}
        <div className="flex items-center">
          <div
            role="presentation"
            onClick={() => handleToast()}
            className="bg-[#02FFFF] text-[#0F1314] text-[16px] leading-[19px] font-[700] font-['Myriad_Pro'] rounded-[50px] p-[10px_20px] mr-[10px] cursor-pointer xl:hidden"
          >
            Getting Started
          </div>
          <div className='border-[1px] border-[#02FFFF] rounded-full'>
            <Web3Button icon={'hide'} />
          </div>
          {/* <span
            role="presentation"
            onClick={() => handleToast()}
            className="inline-block cursor-pointer text-white font-['Myriad_Pro'] text-[16px] xl:text-[12px] leading-[19px] xl:leading-[14px] font-[700] p-[10px_20px] xl:px-[15px] rounded-[50px] border border-[#02FFFF]"
          >
            Connect Wallet
          </span> */}
          <img
            className="hidden w-[28px] h-[24px] xl:block ml-[20px] cursor-pointer"
            role="presentation"
            onClick={() => setShowPop(!showPop)}
            alt="hamber"
            src={showPop ? CloseSvg : HambergerSvg}
          />
        </div>
      </S.InnerContainer>
      <S.Pop
        className="h-screen w-screen fixed top-[70px] left-0 flex flex-col justify-start items-center py-5"
        style={{
          opacity: isMobile && showPop ? 1 : 0,
          pointerEvents: isMobile && showPop ? 'auto' : 'none',
        }}
        onClick={() => setShowPop(!showPop)}
      >
        {/* <div className='border-[1px] border-[#02FFFF] rounded-full'>
          <Web3Button
            icon=''
            size="small"
          />
        </div> */}
        {/* <span
          role="presentation"
          onClick={() => handleToast()}
          className="inline-block cursor-pointer my-[20px] text-white font-['Myriad_Pro'] text-[20px] leading-[24px] font-[700] p-[15px_40px] rounded-[50px] border border-[#02FFFF]"
        >
          Connect Wallet
        </span> */}
        <span
          role="presentation"
          onClick={() => handleToast()}
          className="inline-block cursor-pointer text-[#0F1314] font-['Myriad_Pro'] text-[20px] leading-[24px] font-[700] p-[15px_40px] rounded-[50px] border border-[#02FFFF] bg-[#02FFFF]"
        >
          Getting Started
        </span>
        <div className="flex flex-col justify-start items-start w-[220px]">
          <S.MenuItem>
            <Link to='/ai-incubator'>AI Incubator</Link>
          </S.MenuItem>
          <S.MenuItem>
            <Link to="/ai-marketplace">AI Marketplace</Link>
          </S.MenuItem>
          <S.MenuItem onClick={() => handleToast()}>Tokenomics</S.MenuItem>
          <S.MenuItem onClick={() => handleToast()}>Whitepaper</S.MenuItem>
        </div>
      </S.Pop>
    </S.Container>
  );
};

export default Header;
