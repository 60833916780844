import styled from 'styled-components';

export const Mask = styled.div`
  background-color: rgba(15, 19, 20, 0.8);
  z-index: 10000;
`;

export const Content = styled.div`
  width: 600px;
  box-shadow: 0px 0px 20px 0px #02ffff;
  background: linear-gradient(
      149.41deg,
      rgba(255, 255, 255, 0) 31.61%,
      rgba(255, 255, 255, 0.1) 49.26%,
      rgba(255, 255, 255, 0) 68.49%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #0f1314;
  & > :not(img) {
    width: 500px;
  }

  @media screen and (max-width: 899px) {
    width: 350px;
    & > :not(img) {
      width: 330px;
    }
  }
`;

export const Title = styled.span`
  text-align: left;
  color: #02ffff;
  font-family: 'Myriad Pro';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;
  @media screen and (max-width: 899px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Estimate = styled.span`
  color: #00ff75;
  font-family: 'Myriad Pro';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 20px;
  &::before {
    content: 'Estimate PNL';
    color: white;
  }

  @media screen and (max-width: 899px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const InpuAmount = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
