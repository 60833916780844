import React, { useState, useRef, useEffect, useCallback } from 'react';
import * as echarts from 'echarts';
import DetailBg from 'assets/images/balancer-bg-1.png';
import NoticeSvg from 'assets/images/balancer-notice.svg';
import MindRed from 'assets/images/mind-red.svg';
import MindGreen from 'assets/images/mind-green.svg';
import MindPurple from 'assets/images/mind-purple.svg';
import Incubator from 'assets/images/balancer-box.png';
import UpGreen from 'assets/images/up-green.svg';
import DownRed from 'assets/images/down-red.svg';
import * as S from './styles';
import { Link } from 'react-router-dom';

const Balancer = (props) => {
  const timeList = ['24H', '7D', '30D'];

  const ChartRef = useRef(null);

  let myChart = null;

  const emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: 'rgba(0,0,0,0.3)',
    },
  };

  const options = {
    tooltip: {},
    toolbox: {
      show: false,
      feature: {
        magicType: {
          type: ['stack'],
        },
        dataView: {},
      },
    },
    color: ['#00FF75', '#958CFF', '#F50404'],
    legend: {
      show: true,
      data: ['AI Valut', 'AI Balancer', 'AI Converge'],
      itemWidth: 15,
      itemHeight: 15,
      itemGap: 30,
      borderRadius: 0,
      bottom: 10,
      textStyle: {
        color: '#fff',
        fontFamily: 'Myriad Pro',
      },
    },
    xAxis: {
      data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子'],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      offset: 10,
    },
    yAxis: [
      {
        type: 'value',
        name: 'Profit($)',
        min: 0,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter(value, index) {
            if (value > 1000) {
              return `${parseInt(value / 1000, 10)}K`;
            }
            return value;
          },
          color: 'rgba(255,255,255,0.5)',
          fontFamily: 'Myriad Pro',
          rotate: 0,
        },
        nameLocation: 'center',
        nameGap: 30,
        nameTextStyle: {
          fontFamily: 'Myriad Pro',
        },
      },
      {
        type: 'value',
        name: 'Count',
        min: 0,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter(value, index) {
            if (value > 1000) {
              return `${parseInt(value / 1000, 10)}K`;
            }
            return value;
          },
          color: '#02FFFF',
          fontFamily: 'Myriad Pro',
        },
        nameLocation: 'center',
        nameGap: 30,
        nameRotate: -90,
        nameTextStyle: {
          color: '#02FFFF',
          fontFamily: 'Myriad Pro',
        },
      },
    ],
    series: [
      {
        name: 'AI Valut',
        type: 'bar',
        stack: 'one',
        emphasis: emphasisStyle,
        data: [5, 20, 36, 10, 10, 20],
      },
      {
        name: 'AI Balancer',
        type: 'bar',
        stack: 'one',
        emphasis: emphasisStyle,
        data: [5, 20, 36, 10, 10, 20],
      },
      {
        name: 'AI Converge',
        type: 'bar',
        stack: 'one',
        emphasis: emphasisStyle,
        data: [5, 20, 36, 10, 10, 20],
      },
      {
        name: 'Temperature',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter(value, index) {
            if (value > 1000) {
              return `${parseInt(value / 1000, 10)}K`;
            }
            return value;
          },
        },
        data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2],
        smooth: true,
        lineStyle: {
          color: '#02FFFF',
        },
        itemStyle: {
          color: '#02FFFF',
          borderColor: 'none',
        },
        symbol: 'circle',
        symbolSize: 10,
      },
    ],
  };

  useEffect(() => {
    window.console.log('有了---', ChartRef.current);
    if (ChartRef.current && !myChart) {
      myChart = echarts.init(ChartRef.current);
      myChart.setOption(options);
    }
  }, [ChartRef]);

  const handleWindowResize = useCallback(() => {
    window.console.log('触发了', myChart);
    if (ChartRef.current && myChart) return myChart.resize();
    return null;
  });

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const [timeIndex, setTimeIndex] = useState(0);

  const timeFilter = (index) => {
    window.console.log('触发下标---', index);
    setTimeIndex(index);
  };

  const typeList = ['By Profit', 'By Volume'];

  const [typeIndex, setTypeIndex] = useState(0);

  const typeFilter = (index) => {
    setTypeIndex(index);
  };

  const [List, setList] = useState([1, 2, 3, 4]);

  const [LeftList, setLeftList] = useState([
    {
      key: 'Total $POT Staked',
      value: '10,066,343',
      ext: '$POT',
    },
    {
      key: 'Total Buy Back & Burn',
      value: '10,066,343',
    },
    {
      key: 'Trading Volume',
      value: '10,066,343',
    },
    {
      key: 'Total Nett Contribution',
      value: '10,066,343',
    },
  ]);

  const PerformanceList = [
    {
      name: 'AI Vault',
      color: '#00FF75',
      icon: MindGreen,
      isUP: true,
      value: '958.76K',
      rate: '130.94',
    },
    {
      name: 'AI Balancer',
      color: '#958CFF',
      icon: MindPurple,
      isUP: false,
      value: '1.02M',
      rate: '-71.98',
    },
    {
      name: 'AI Converge',
      color: '#F50404',
      icon: MindRed,
      isUP: false,
      value: '-1.8K',
      rate: '-108.43',
    },
  ];

  const list = [
    {title: 'Specimen X01', active: true, route: '/ai-vault'},
    {title: 'Coming Soon', active: false, route: ''},
    {title: 'Coming Soon', active: false, route: ''},
    {title: 'Coming Soon', active: false, route: ''},
    {title: 'Coming Soon', active: false, route: ''},
    {title: 'Coming Soon', active: false, route: ''},
    {title: 'Coming Soon', active: false, route: ''},
    {title: 'Coming Soon', active: false, route: ''},
  ];

  return (
    <S.Container className="flex flex-col justify-start items-center w-full overflow-x-hidden">
      {/* <S.Detail className="hidden relative flex pt-[80px] w-[1527px] justify-start lg:flex-col lg:justify-center lg:items-center">
        <S.DetailBackground className="absolute right-[-487px] z-[-1];" src={DetailBg} />
        <div className="flex flex-col justify-start items-center">
          <div className="flex justify-between items-start w-[911px] lg:flex-col lg:justify-start lg:items-center">
            <div className="flex justify-center relative w-[448px] h-[531px] md:w-[calc(448px*0.7)] md:h-[calc(531px*0.7)]">
              <img className="w-full h-full" alt="incubator" src={Incubator} />
              <S.IncubatorShadow className="absolute bottom-[-42px] w-[723px] h-[140px] md:w-[calc(723px*0.6)] md:h-[calc(140px*0.6)]" />
            </div>
            <div className="flex flex-col justify-start items-center w-[404px] lg:mt-[50px]">
              <span className="inline-block text-left w-full text-[#02FFFF] font-['Myriad_Pro'] text-[bold] text-[24px] leading-[29px] mb-[30px] lg:text-center">
                TradeGDT Overview
              </span>
              <S.TVL className="flex flex-col justify-between items-start mb-[50px] lg:items-center">
                <span className="font-['Myriad_Pro'] text-white opacity-50 font-[700] text-[16px] leading-[19px] md:text-[12px] md:leading-[14px]">
                  TVL (USDT)
                </span>
                <span className="font-['Myriad_Pro'] text-white font-[700] text-[36px] leading-[43px] md:text-[20px] md:leading-[24px]">
                  $ 3,024,981
                </span>
              </S.TVL>
              <div className="grid grid-cols-2 gap-x-[52px] gap-y-[50px] md:gap-[30px]">
                {LeftList.map((eItem, eIndex) => (
                  <div
                    key={eItem.key}
                    className="flex flex-col justify-between items-start w-[177px] h-[58px] md:w-[160px] md:h-[43px]"
                  >
                    <span className="whitespace-nowrap font-['Myriad_Pro'] text-white opacity-50 font-[700] text-[16px] leading-[19px] md:text-[12px] md:leading-[14px]">
                      {eItem.key}
                    </span>
                    <span className="whitespace-nowrap font-['Myriad_Pro'] text-white font-[600] text-[24px] leading-[29px] md:text-[16px] md:leading-[19px]">
                      {eItem.value} {eItem.ext}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <S.ChartWrapper className="flex flex-col justify-start items-center mt-[50px]">
            <div className="flex justify-between items-center w-full mb-[20px] lg:flex-col lg:justify-start">
              <div className="flex flex-col justify-start items-start lg:self-start lg:mb-[20px]">
                <span className="text-[#02FFFF] font-[600] text-[24px] leading-[29px] whitespace-nowrap font-['Myriad_Pro'] mb-[5px] md:text-[16px] md:leading-[19px]">
                  TradeGDT Performance
                </span>
                <div className="flex items-start">
                  <span
                    className="text-white font-[400] text-[16px] leading-[19px] whitespace-nowrap font-['Myriad_Pro'] mr-[10px] md:text-[12px] md:leading-[14px]"
                    style={{
                      textShadow: '0px 0px 1px black',
                    }}
                  >
                    Performance of MEV Types
                  </span>
                  <img
                    className="w-[18px] h-[20px] md:w-[13px] md:h-[15px]"
                    alt="notice"
                    src={NoticeSvg}
                  />
                </div>
              </div>
              <S.Filter>
                {typeList.map((tpItem, tpIndex) => (
                  <S.FilterItem
                    key={tpItem}
                    onClick={() => typeFilter(tpIndex)}
                    data-active={tpIndex === typeIndex}
                  >
                    {tpItem}
                  </S.FilterItem>
                ))}
              </S.Filter>
            </div>
            <div className="flex justify-between items-center w-full lg:flex-col lg:justify-start">
              <div className="flex flex-col justify-start items-center w-[212px]">
                {PerformanceList.map((pItem, pIndex) => (
                  <S.PerformanceBox
                    key={pItem.name}
                    className="flex flex-col justify-center items-start w-full h-[78px]"
                  >
                    <div className="flex items-center mb-[10px]">
                      <img className="w-[15px] h-[15px] mr-[10px]" alt="mind" src={pItem.icon} />
                      <span className="font-['Myriad_Pro'] font-bold text-[16px] leading-[19px] text-[#00FF75]">
                        {pItem.name}
                      </span>
                    </div>
                    <div className="flex self-end">
                      <span className="text-white font-['Myriad_Pro'] font-[400] text-[16px] leading-[19px]">
                        $ {pItem.value}
                      </span>
                      <div className="flex items-center ml-[20px]">
                        <img
                          className="w-[18px] h-[6px] mr-[2px]"
                          alt="upgreen"
                          src={pItem.isUP ? UpGreen : DownRed}
                        />
                        <span
                          className="font-['Myriad_Pro'] font-[400] text-[12px] leading-[14px]"
                          style={{
                            color: pItem.isUP ? '#00FF75' : '#F50404',
                          }}
                        >
                          {pItem.rate}%
                        </span>
                      </div>
                    </div>
                  </S.PerformanceBox>
                ))}
              </div>
              <div
                className="w-[615px] h-[307px] md:w-[329px] md:h-[303px]"
                ref={(r) => {
                  ChartRef.current = r;
                }}
              >
                {null}
              </div>
            </div>
          </S.ChartWrapper>
        </div>
        <div className="flex flex-col justify-start items-center w-[566px] z-50 lg:mt-[50px] md:w-[350px]">
          <div className="flex justify-between items-center w-full mb-[20px]">
            <span className="font-['Myriad_Pro'] font-[600] text-[24px] leading-[29px] text-[#02FFFF] md:text-[16px] md:leading-[19px]">
              Trx Live-Stream
            </span>
            <S.Filter>
              {timeList.map((tItem, tIndex) => (
                <S.FilterItem
                  key={tItem}
                  onClick={() => timeFilter(tIndex)}
                  data-active={tIndex === timeIndex}
                >
                  {tItem}
                </S.FilterItem>
              ))}
            </S.Filter>
          </div>
          <S.Stream className="flex flex-col justify-start items-center w-full px-[30px] pt-[30px] pb-[45px] box-border">
            <div className="flex justify-start items-center w-[506px] md:w-[330px] mb-[25px]">
              <span className="font-['Myriad_Pro'] text-white font-[700] text-[16px] leading-[19px] mr-[10px] md:text-[12px] md:leading-[14px]">
                MEV Live-Stream
              </span>
              <img
                className="w-[18px] h-[20px] md:w-[13px] md:h-[15px]"
                src={NoticeSvg}
                alt="notice"
              />
            </div>
            <div className="flex flex-col justify-start items-center w-[506px] md:w-[330px]">
              <div className="flex justify-between items-center w-full opacity-50">
                <span className="inline-block text-left text-white w-[263px] text-[16px] leading-[19px] font-[400] font-['Myriad_Pro'] md:text-[12px] md:leading-[14px]">
                  Contarct
                </span>
                <span className="inline-block text-center text-white w-[140px] text-[16px] leading-[19px] font-[400] font-['Myriad_Pro'] md:text-[12px] md:leading-[14px]">
                  Profit
                </span>
                <span className="inline-block text-right text-white w-[110px] text-[16px] leading-[19px] font-[400] font-['Myriad_Pro'] md:text-[12px] md:leading-[14px]">
                  Cost
                </span>
              </div>
              {List.map((lItem, lIndex) => (
                <div
                  key={lItem}
                  className="flex justify-between items-center w-full mt-[30px] h-[48px] md:h-[38px]"
                >
                  <div className="flex justify-start items-center w-[263px] ">
                    <S.StreamLeft className="flex flex-col justify-between items-start h-[48px] md:h-[38px]">
                      <span className="text-white text-[16px] leading-[19px] font-[400] font-['Myriad_Pro'] md:text-[12px] md:leading-[14px]">
                        #17154634
                      </span>
                      <span className="text-white text-[16px] leading-[19px] font-[400] font-['Myriad_Pro'] md:text-[12px] md:leading-[14px]">
                        3 mins
                      </span>
                    </S.StreamLeft>
                    <S.StreamIcon src={MindRed} />
                    <span className="whitespace-nowrap text-[16px] leading-[19px] font-[700] font-['Myriad_Pro'] text-[#F50404] md:text-[12px] md:leading-[14px]">
                      AI Converge
                    </span>
                  </div>
                  <span className="inline-block text-center text-white w-[140px] text-[16px] leading-[19px] font-[400] font-['Myriad_Pro'] md:text-[12px] md:leading-[14px]">
                    $20.555558
                  </span>
                  <span className="inline-block text-right text-white w-[110px] text-[16px] leading-[19px] font-[400] font-['Myriad_Pro'] md:text-[12px] md:leading-[14px]">
                    $20.555558
                  </span>
                </div>
              ))}
            </div>
          </S.Stream>
        </div>
      </S.Detail> */}
      <S.Goods className="flex flex-col justify-start items-center w-full">
        <S.GoodsTitle>AI Marketplace</S.GoodsTitle>
        <div className="grid grid-cols-12 items-center gap-[50px] lg:gap-[10px] px-5">
          {list.map((item,index) => (
            <div key={index} className={`col-span-3 flex justify-start flex-col items-center md:col-span-4 ${index == 6 ? 'md:col-start-2' : '' } ${index == 7 ? 'md:col-start-8' : '' }`}>
              <img
                className={`w-[380px] h-[450px] xl:object-contain mb-[10px] md:mb-[0px] md:w-[140px] md:h-[160px] ${item.active ? '' : 'brightness-[.04] grayscale-[.5] drop-shadow-[0_0_20px_rgba(2,255,255,0.5)]'}`}
                alt="incuabox"
                src={Incubator}
              />
              <div 
                className={`w-[250px] lg:w-full h-[69px] md:h-[42px] rounded-[50px] flex justify-center items-center text-center font-['Myriad_Pro'] text-[16px] md:text-[12px] select-none ${ item.active ? 'text-white  box-border border border-[#02FFFF]' : 'bg-[#B9B9B9] text-[#656565]'}`}
              >
                <Link to={item.route} >{item.title}</Link>
              </div>
            </div>
          ))}
        </div>
      </S.Goods>
    </S.Container>
  );
};
export default Balancer;
