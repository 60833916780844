import React, { useState, useEffect, Suspense } from 'react';
// import Footer from 'components/Footer';
// import Header from 'components/Header';
import Pagination from 'components/Pagination';
import useBrowserScroll from 'contexts/scroll';
import useBrowserResize from 'contexts/resize';
import Loading from './Loading';

const MainPage1 = React.lazy(() => import('./MainPage1'));
const MainPage2 = React.lazy(() => import('./MainPage2'));
const MainPage3 = React.lazy(() => import('./MainPage3'));
const MainPage4 = React.lazy(() => import('./MainPage4'));
const MainPage5 = React.lazy(() => import('./MainPage5'));
// const Balancer = React.lazy(() => import('./balancer'));
// const BalancerDetail = React.lazy(() => import('./BalancerDetail'));
const MainPage6 = React.lazy(() => import('./MainPage6'));
const MainPage7 = React.lazy(() => import('./MainPage7'));
const MainPage8 = React.lazy(() => import('./MainPage8'));
const MainPage9 = React.lazy(() => import('./MainPage9'));

const Home = (props) => {
  const [index, setIndex] = useState(0);

  const { scroll } = useBrowserScroll();
  const { width } = useBrowserResize();
  const [PageDom, setPageDom] = useState({});
  useEffect(() => {
    setPageDom(document.getElementsByClassName('page_container'));
    // window.console.log('拿到dom---', PageDom);
  }, [width]);
  useEffect(() => {
    // window.console.log('滚动高度---', scroll);
    if (PageDom) {
      // window.console.log(PageDom[8].offsetTop - PageDom[8].offsetHeight / 2);
      for (let dIndex = 0; dIndex < PageDom.length; dIndex += 1) {
        if (PageDom[dIndex].offsetTop - PageDom[dIndex].offsetHeight / 2 > scroll) {
          if (dIndex - 1 === dIndex || (!dIndex && dIndex === index)) break;
          setIndex(dIndex ? dIndex - 1 : 0);
          break;
        } else if (dIndex === PageDom.length - 1) setIndex(8);
      }
    }
  }, [scroll, width]);


  return (
    <>
      {/* <Header /> */}
      <Suspense fallback={<Loading />}>
        <MainPage1 InView={setIndex} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <MainPage2 InView={setIndex} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <MainPage3 InView={setIndex} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <MainPage4 InView={setIndex} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <MainPage5 InView={setIndex} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <MainPage6 InView={setIndex} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <MainPage7 InView={setIndex} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <MainPage8 InView={setIndex} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <MainPage9 InView={setIndex} />
      </Suspense>
      <Pagination index={index} isLeft={false} align="center" />
      {/* <Footer /> */}
    </>
  );
};
export default Home;
