import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Mask = styled(motion.div)`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999999;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Pop = styled(motion.div)`
  border-radius: 20px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding:30px 20px;
  width: 500px;
  // min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  user-select: none;
  .btn{
    width: 45%;
    text-align:center;
    border-radius: 6px;
    padding: 10px 0;
    font-famliy: 'MyriadPro';
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    &:nth-of-type(2){
      background: #0F1314;
    }
  }
`;

export const Logo = styled.img`
  height: 68px;
  @media screen and (max-width: 1199px) {
    height: 68px;
  }
`;