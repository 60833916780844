import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  background: ${({ scrolled }) => (scrolled ? '#0000008a' : 'transparent')};
  transition: background 0.3s;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // max-width: 1200px;
  width: 100%;
  padding: 20px 50px;
  border-box;
  @media screen and (max-width: 1250px) {
    padding: 20px;
  }
  @media screen and (max-width: 899px) {
    backdrop-filter: blur(5px);
  }
`;

export const Logo = styled.img`
  height: 40px;
  @media screen and (max-width: 1199px) {
    height: 34px;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const ConnectButton = styled.div`
  cursor: pointer;
  color: #ffffff;
  padding: 10px 20px;
  border: 1px solid ${(props) => props.theme.main};
  border-radius: 999px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.5;
  }
`;

export const MenuItem = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  text-decoration: none;
  font-family: 'Myriad-Bold';
  font-size: 12px;
  cursor: pointer;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border: 2px solid ${(props) => props.theme.main};
    border-radius: 999px;
  }
  &:active::before {
    background: linear-gradient(8.48deg, #02ffff 15.03%, rgba(2, 255, 255, 0) 92.61%);
  }
  @media screen and (max-width: 1199px) {
    margin-top: 40px;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Pop = styled.div`
  background: linear-gradient(180deg, rgba(15, 19, 20, 0) 0%, rgba(15, 19, 20, 0.75) 13.54%);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: opacity 0.3s;
`;
