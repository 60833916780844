import React, { useEffect, useRef, useState, useMemo } from 'react';
import graphVideo from 'assets/videos/Chat.mp4';
import compGif from 'assets/images/Comp 2-Alpha.gif';
import IncubatorBg from 'assets/images/Incubator_bg.png';
import IncubatorBgMobile from 'assets/images/Incubator_bg_mobile.png';
import Brain_In_Incubator_Blue_Ball from 'assets/videos/Brain_In_Incubator_Blue_Ball.mp4';
import Brain_In_Incubator_Blue_Ball_Mobile from 'assets/videos/Brain_In_Incubator_Blue_Ball_Mobile.mp4';
import Brain_In_Incubator_Brain_Green from 'assets/videos/Brain_In_Incubator_Brain_Green.mp4';
import Brain_In_Incubator_Brain_Mobile_Green from 'assets/videos/Brain_In_Incubator_Brain_Mobile_Green.mp4';
import Brain_In_Incubator_Brain_Blue from 'assets/videos/Brain_In_Incubator_Brain_Blue.mp4';
import Brain_In_Incubator_Brain_Mobile_Blue from 'assets/videos/Brain_In_Incubator_Brain_Mobile_Blue.mp4';
import Brain_In_Incubator_Brain_Yellow from 'assets/videos/Brain_In_Incubator_Brain_Yellow.mp4';
import Brain_In_Incubator_Brain_Mobile_Yellow from 'assets/videos/Brain_In_Incubator_Brain_Mobile_Yellow.mp4';
import Brain_In_Incubator_Brain_Purple from 'assets/videos/Brain_In_Incubator_Brain_Purple.mp4';
import Brain_In_Incubator_Brain_Mobile_Purple from 'assets/videos/Brain_In_Incubator_Brain_Mobile_Purple.mp4';
import Brain_In_Incubator_Brain_Red from 'assets/videos/Brain_In_Incubator_Brain_Red.mp4';
import Brain_In_Incubator_Brain_Mobile_Red from 'assets/videos/Brain_In_Incubator_Brain_Mobile_Red.mp4';
import BrainEnergyBall from 'assets/videos/Brain_Energy_Ball.mp4';
import Pagination from 'components/Pagination';
import verticalDots from 'assets/images/vertical_dots.svg';
import horizontalDots from 'assets/images/horizontal_dots.svg';
import x from 'assets/images/x.svg';
import verticalDotsYellow from 'assets/images/vertical_dots_yellow.svg';
import horizontalDotsYellow from 'assets/images/horizontal_dots_yellow.svg';
import xYellow from 'assets/images/x_yellow.svg';
import alertBg from 'assets/images/alert_bg.svg';
import ownerContainerBg from 'assets/images/owner_container_bg.svg';
import tips from 'assets/images/tips.svg';
import tipsYellow from 'assets/images/tips_yellow.svg';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { getTradeData, mintIncubator } from 'api';
import * as S from './styles';
import Loading from 'components/Loading';

const MainPage4 = (props) => {
  const { address, isConnected } = useAccount();
  const [connectWalletAlert, setConnectWalletAlert] = useState(false);
  const [disclaimerAlert, setDisclaimerAlert] = useState(false);
  const [userNotFoundAlert, setUserNotFoundAlert] = useState(false);
  const { open: openWeb3Modal } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const message = 'Welcome to TradeGDT!\nPlease sign this message for verification.\nThere would not be any charges for this action.';
  let signCached = localStorage.getItem('sign') ?? ''
  let addressCached = localStorage.getItem('address') ?? ''
  const [sign, setSign] = useState(signCached);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const brains = [
    { volume: 0, pc: Brain_In_Incubator_Blue_Ball, mobile: Brain_In_Incubator_Blue_Ball_Mobile },
    { volume: 300000, pc: Brain_In_Incubator_Brain_Green, mobile: Brain_In_Incubator_Brain_Mobile_Green },
    { volume: 500000, pc: Brain_In_Incubator_Brain_Blue, mobile: Brain_In_Incubator_Brain_Mobile_Blue },
    { volume: 800000, pc: Brain_In_Incubator_Brain_Yellow, mobile: Brain_In_Incubator_Brain_Mobile_Yellow },
    { volume: 1000000, pc: Brain_In_Incubator_Brain_Purple, mobile: Brain_In_Incubator_Brain_Mobile_Purple },
    { volume: 1200000, pc: Brain_In_Incubator_Brain_Red, mobile: Brain_In_Incubator_Brain_Mobile_Red },
  ];
  const [currentBrain, setCurrentBrain] = useState(null);

  const getTradeDataCall = async () => {
    const res = await getTradeData({
      address,
      message,
      sign,
    });
    console.log(res);
    if (res.data.code === 0) {
      const info = res.data.data;
      setInfo(info);
      if (info && info.isMint) {
        for (let index = brains.length - 1; index >= 0; index--) {
          const element = brains[index];
          if (index == 0 || info.totalTradeVolume >= element.volume) {
            setCurrentBrain(element);
            break;
          }
        }
      }
    }
  };

  const cleanData = async () => {
    localStorage.removeItem('address');
    localStorage.removeItem('sign');
    setInfo(null);
    setSign(null);
    setCurrentBrain(null);
    addressCached = '';
    signCached = '';
  }

  useEffect(() => {
    console.log('---', isConnected, '--', address);
    if (!isConnected && addressCached) {
      cleanData();
      return
    }
    if (addressCached && addressCached !== address) {
      cleanData();
      return
    }
    if (isConnected && address) {
      localStorage.setItem('address', address);
      addressCached = address;
      setConnectWalletAlert(false);
    }
    if (isConnected && address && !sign) {
      setConnectWalletAlert(false);
      signMessageAsync({ message })
        .then((res) => {
          localStorage.setItem('sign', res);
          setSign(res);
        })
        .catch((err) => {
          // disconnect();
          // setSign('');
          // localStorage.clear();
        });
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (address && sign) {
      getTradeDataCall();
    }
  }, [isConnected, address, sign]);

  const mint = async () => {
    if (!isConnected) {
      // setConnectWalletAlert(true);
      openWeb3Modal();
      return;
    }
    if (!sign) {
      signMessageAsync({ message })
        .then((res) => {
          localStorage.setItem('sign', res);
          setSign(res);
        })
        .catch((err) => {
          // disconnect();
          // setSign('');
          // localStorage.clear();
        });
    }
    if (!info) {
      return;
    }
    if (info.id === '') {
      setUserNotFoundAlert(true);
    }
    if (info.isMint) {
      return;
    }
    setLoading(true);
    const res = await mintIncubator({
      address,
      message,
      sign,
    });
    setLoading(false);
    if (res.data.code === 0) {
      getTradeDataCall();
    }
  };

  return (
    <S.Container id="Incubator">
      <S.Bg src={IncubatorBg} className='block sm:hidden'></S.Bg>
      <S.Bg src={IncubatorBgMobile} className='hidden sm:block'></S.Bg>
      {
        currentBrain ? 
        <div>
          <S.Video autoPlay loop muted playsInline className='block sm:hidden'>
            <source src={currentBrain.pc} type="video/mp4"></source>
          </S.Video>
          <S.Video autoPlay loop muted playsInline className='hidden sm:block'>
            <source src={currentBrain.mobile} type="video/mp4"></source>
          </S.Video>
        </div>
        : null
      }
      <S.InnerContainer>
        <S.OwnerContainer>
          <img src={ownerContainerBg} className="absolute left-0 top-0 w-full -z-10" alt="" />
          <S.div onClick={() => setDisclaimerAlert(true)}>
            <img
              src={tips}
              className="absolute top-[25px] right-[25px] w-[25px] cursor-pointer"
              alt=""
            />
          </S.div>
          <div>Brain Owner</div>
          <div className="text-2xl font-semibold">{info ? info.name : '--'}</div>
          <div className="mt-5 sm:mt-2">Total Trading Volume</div>
          <div className="box-bg text-2xl font-semibold rounded-xl p-2 sm:p-1 text-center mt-2 sm:mt-0">
            {info ? Number(info.totalTradeVolume).toLocaleString() : '--'}
          </div>
          <div className="mt-5 sm:mt-2">Sharpe ratio</div>
          <div className="box-bg text-2xl font-semibold rounded-xl p-2 sm:p-1 text-center mt-2 sm:mt-0">
            {info ? info.sharpeRatio : '--'}
          </div>
          <div className="mt-5 sm:mt-2">P&L</div>
          <div className="box-bg text-2xl font-semibold rounded-xl p-2 sm:p-1 text-center mt-2 sm:mt-0">
            {info ? Number(info.pnl).toLocaleString() : '--'}
          </div>
          <S.div
            className="button-bg inline-block px-6 py-3 text-black text-2xl font-semibold rounded-xl mt-8 mx-auto cursor-pointer sm:mt-6 sm:px-4 sm:py-2"
            onClick={mint}
          >
            {loading ? 
              <Loading></Loading>
              : !address ? 'Connect Wallet'
              : !sign ? 'Sign Message'
              : info && info.isMint ? 'MINTED' : 'MINT'
            }
          </S.div>
          <img src={verticalDots} className="absolute bottom-[50px] left-[20px] h-[80px] sm:bottom-[30px] sm:left-[10px]" alt="" />
          <img
            src={horizontalDots}
            className="absolute bottom-[20px] right-[25px] w-[50px]"
            alt=""
          />
        </S.OwnerContainer>
      </S.InnerContainer>
      {connectWalletAlert ? (
        <S.AlertContainer>
          <S.AlertMask />
          <S.AlertContent>
            <img src={alertBg} className="absolute left-0 top-0 w-full" alt="" />
            <S.AlertClose onClick={() => setConnectWalletAlert(false)}>
              <img src={x} className="w-[15px]" alt="" />
            </S.AlertClose>
            <div className="text-center text-lg mt-5">
              Please connect your wallet in order to mint your own neuron.
            </div>
            <S.div
              className="relative z-10 button-bg text-black text-2xl font-semibold rounded-xl p-2 py-3 text-center mt-8 mb-4 mx-auto cursor-pointer"
              onClick={() => openWeb3Modal()}
            >
              Connect Wallet
            </S.div>
            <img
              src={verticalDots}
              className="absolute bottom-[50px] left-[20px] h-[80px]"
              alt=""
            />
            <img
              src={horizontalDots}
              className="absolute bottom-[20px] right-[25px] w-[50px]"
              alt=""
            />
          </S.AlertContent>
        </S.AlertContainer>
      ) : null}
      {userNotFoundAlert ? (
        <S.AlertContainer>
          <S.AlertMask />
          <S.AlertContent>
            <img src={alertBg} className="absolute left-0 top-0 w-full" alt="" />
            <S.AlertClose onClick={() => setUserNotFoundAlert(false)}>
              <img src={x} className="w-[15px]" alt="" />
            </S.AlertClose>
            <div className="text-center text-lg mt-5 mb-5">
              Please register on the TraderDAO website to unlock the extraordinary world of neuron
              minting.
            </div>
            <img
              src={verticalDots}
              className="absolute bottom-[50px] left-[20px] h-[80px]"
              alt=""
            />
            <img
              src={horizontalDots}
              className="absolute bottom-[20px] right-[25px] w-[50px]"
              alt=""
            />
          </S.AlertContent>
        </S.AlertContainer>
      ) : null}
      {disclaimerAlert ? (
        <S.AlertContainer>
          <S.AlertMask />
          <S.AlertContent className="warning">
            <img src={alertBg} className="absolute left-0 top-0 w-full" alt="" />
            <S.AlertClose className="close-warning" onClick={() => setDisclaimerAlert(false)}>
              <img src={xYellow} className="w-[15px]" alt="" />
            </S.AlertClose>
            <div className="flex justify-center items-center">
              <img src={tipsYellow} className="w-[25px] cursor-pointer" alt="" />
              <div className="text-2xl ml-2">Disclaimer</div>
            </div>
            <div className="text-center text-lg mt-5 mb-5">
              This is a soulbound NFT and cannot be transferred or sold.
            </div>
            <img
              src={verticalDotsYellow}
              className="absolute bottom-[50px] left-[20px] h-[80px]"
              alt=""
            />
            <img
              src={horizontalDotsYellow}
              className="absolute bottom-[20px] right-[25px] w-[50px]"
              alt=""
            />
          </S.AlertContent>
        </S.AlertContainer>
      ) : null}
    </S.Container>
  );
};
export default MainPage4;
