import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CloseSvg from 'assets/images/x.svg';
import * as S from './styles';
import { useBalance, useAccount, useContractRead, usePrepareContractWrite, useContractWrite, useWaitForTransaction, erc20ABI } from 'wagmi'
import depositWithdrawContractABI from '../../../../contracts/abi-deposit-withdraw-contract'
import { useDispatch, useSelector } from 'react-redux'
import { ethers } from 'ethers';
import Loading from 'components/Loading'
import { useSnackbar } from 'notistack';
import { floor } from 'lodash';
import { walletBalances } from 'api/tradegdt.js';

const PledgePop = ({ closePop }) => {
  const inputAmount = useRef()
  const [amountStr, setAmountStr] = useState('');
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { address, isConnected } = useAccount()
  const userState = useSelector((state) => state.user);
  const aiVaultContract = process.env.REACT_APP_AIVAULT_CONTRACT;
  const pusdContract = process.env.REACT_APP_PUSD_CONTRACT;
  const { enqueueSnackbar } = useSnackbar();
  const [pledgeNotice, setPledgeNotice] = useState(false);

  const amountChange = (e) => {
    const { value } = e.target;
    setAmountStr(value)
    setAmount(Number(value));
  };

  const [day, setDay] = useState(7);

  const dayChange = (e) => {
    const { value } = e.target;
    if (value && value < 7) {
      setDay(7);
    } else if (value > 90) {
      setDay(90);
    } else {
      setDay(value);
    }
  };

  const { data: _totalReceived } = useContractRead({
    address: aiVaultContract,
    abi: depositWithdrawContractABI,
    functionName: 'totalReceived',
    args: [],
    enabled: true,
    watch: true,
  })
  const totalReceived = _totalReceived ? ethers.utils.formatUnits(_totalReceived.toString(), 6) : 0;

  const { data: _maxVaultLimit } = useContractRead({
    address: aiVaultContract,
    abi: depositWithdrawContractABI,
    functionName: 'maxVaultLimit',
    args: [],
    enabled: true,
    watch: true,
  })
  const maxVaultLimit = _maxVaultLimit ? ethers.utils.formatUnits(_maxVaultLimit.toString(), 6) : 0;

  const { data: _allowance } = useContractRead({
    address: pusdContract,
    abi: erc20ABI,
    functionName: 'allowance',
    args: [address, aiVaultContract],
    enabled: Boolean(address),
    watch: true,
  })
  const allowance = _allowance ? _allowance : 0;

  const {data: _pusdBalance } = useBalance({
    address: address, 
    token: pusdContract,
    enabled: Boolean(address),
    watch: true,
  })
  const pusdBalance = parseFloat(_pusdBalance?.formatted ?? '0');

  const { config: approveConfig } = usePrepareContractWrite({
    address: pusdContract,
    abi: erc20ABI,
    functionName: 'approve',
    args: [
      aiVaultContract,
      ethers.utils.parseUnits('99999999999999999999999999999999', _pusdBalance?.decimals)
    ],
    enabled: Boolean(address) && Boolean(_pusdBalance),
  })
  // console.log(approveConfig);
  const { data: _approved, write: callApprove } = useContractWrite({
    ...approveConfig,
    onError: (err) => {
      setLoading(false);
      const msg = err.details || err.cause?.reason || err.cause?.cause || err.message;
      enqueueSnackbar(msg, {
        variant: 'error'
      });
    },
    onSuccess: (data) => {
      
    },
  })
  // console.log('callApprove', callApprove);

  const waitForApproveTransaction = useWaitForTransaction({
    scopeKey: `waittrx`,
    hash: _approved?.hash,
    onSuccess(data) {
      setLoading(false);
      enqueueSnackbar('Approve successful', {
        variant: 'success'
      });
    },
  })

  const { config: depositConfig } = usePrepareContractWrite({
    address: aiVaultContract,
    abi: depositWithdrawContractABI,
    functionName: 'deposit',
    args: [
      ethers.utils.parseUnits(inputAmount && inputAmount?.current?.value ? inputAmount.current.value.toString() : '0' , _pusdBalance?.decimals),
      userState.id,
    ],
    enabled: Boolean(address) && allowance > 0 && amount > 0,
  })
  console.log(`Deposit Amount : ${inputAmount?.current?.value}`)
  console.log(`Deposit Amount In USDT decimal: ${ethers.utils.parseUnits(inputAmount && inputAmount?.current?.value ? inputAmount.current.value.toString() : '0' , _pusdBalance?.decimals)}`)

  const { data: _deposit, write: callDeposit } = useContractWrite({
    ...depositConfig,
    onError: (err) => {
      setLoading(false);
      const msg = err.details || err.cause?.reason || err.cause?.cause || err.message;
      enqueueSnackbar(msg, {
        variant: 'error'
      });
    },
    onSuccess: (data) => {
      
    },
  })
  const waitForDepositTransaction = useWaitForTransaction({
    scopeKey: `waittrx`,
    hash: _deposit?.hash,
    onSuccess(data) {
      setLoading(false);
      enqueueSnackbar('Pledge successful', {
        variant: 'success'
      });
    },
  })

  const onPledge = () => {
    if (loading) {
      return;
    }
    if (amount <= 0) {
      enqueueSnackbar('Amount required', {
        variant: 'error'
      });
      return;
    }
    if (amount < 10) {
      setPledgeNotice(true);
      return;
    }
    setLoading(true);
    if (allowance <= 0) {
      callApprove?.()
      return;
    }
    callDeposit?.();
  }

  return (
    <S.Mask className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center">
      <S.Content className="relative rounded-[10px] flex flex-col justify-start items-center p-[50px] md:p-[20px_10px] box-border">
        <img
          className="absolute w-[20px] h-[20px] top-[20px] right-[20px] cursor-pointer"
          role="presentation"
          onClick={() => closePop(false)}
          alt="close"
          src={CloseSvg}
        />
        <S.Title>Pledge Amount</S.Title>
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center w-[441px] md:w-[290px] h-[59px] box-border p-[10px_10px_10px_20px] rounded-[50px] bg-[rgba(255,255,255,0.1)]">
            <S.InpuAmount
              ref={inputAmount}
              type="number"
              className="flex-1 text-[rgba(255,255,255,.3)] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] bg-transparent outline-0"
              placeholder="Enter amount"
              value={amountStr}
              onChange={(e) => amountChange(e)}
            />
            <div
              role="presentation"
              onClick={() => {setAmount(pusdBalance);setAmountStr(pusdBalance.toString());}}
              className="flex justify-center items-center px-2 h-[39px] md:h-[34px] rounded-[50px] bg-[#02FFFF] text-[#0F1314] font-['Myriad_Pro'] font-[400] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] cursor-pointer"
            >
              Max {floor(Number(pusdBalance), 2).toLocaleString('en', { minimumFractionDigits: 2 })}
            </div>
          </div>
          <span className="text-white text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] font-['Myriad_Pro'] font-[700]">
            USDT
          </span>
        </div>
        {/* <div className='text-[#02FFFF] mt-[10px]'>Available Amount: （${totalReceived.toLocaleString('en', { minimumFractionDigits: 2 })}/${maxVaultLimit.toLocaleString('en', { minimumFractionDigits: 2 })}）</div> */}
        {/* <S.Title>Pledge Countdown</S.Title>
        <div className="flex justify-between items-center mb-[30px]">
          <div className="flex justify-between items-center w-[441px] md:w-[290px] h-[59px] box-border p-[10px_10px_10px_20px] rounded-[50px] bg-[rgba(255,255,255,0.1)]">
            <S.InpuAmount
              type="number"
              className="flex-1 text-[rgba(255,255,255,.3)] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] bg-transparent outline-0"
              placeholder="Enter day"
              value={day}
              onChange={(e) => dayChange(e)}
            />
            <div
              role="presentation"
              onClick={() => setDay(90)}
              className="flex justify-center items-center w-[59px] md:w-[52px] h-[39px] md:h-[34px] rounded-[50px] bg-[#02FFFF] text-[#0F1314] font-['Myriad_Pro'] font-[400] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] cursor-pointer"
            >
              max
            </div>
          </div>
          <span className="text-white text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] font-['Myriad_Pro'] font-[700]">
            days
          </span>
        </div> */}
        {/* <S.Estimate>+31%</S.Estimate> */}
        <span className="inline-block text-left text-[#FFF502] font-['Myriad_Pro'] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] mb-[30px] mt-[30px]">
        *Only Arbitrum One chain USDT is allowed to pledge*
        </span>
        <S.ConfirmBtn onClick={onPledge}>
          <span className="text-white ont-['Myriad_Pro'] font-bold text-[24px] md:text-[16px] leading-[29px] md:leading-[19px] mb-1">
            {
              loading ? 
              <Loading color='#FFF'></Loading>
              : allowance <= 0 ? 'Approve' : 'Pledge'
            }
          </span>
          <span className="text-white ont-['Myriad_Pro'] text-[12px] md:text-[10px] leading-[14px] md:leading-[12px]">
            (USDT)
          </span>
        </S.ConfirmBtn>
      </S.Content>
      {
        pledgeNotice ? 
        <div className='fixed top-0 left-0 w-screen h-screen flex justify-center items-center'>
          <div className='w-full h-full absolute inset-0 bg-black bg-opacity-60'></div>
          <S.Content className='relative rounded-[10px] flex flex-col justify-center items-center px-[50px] py-[30px] md:p-[50px_10px] box-border'>
            <img
              className="absolute z-10 w-[20px] h-[20px] top-[20px] right-[20px] cursor-pointer"
              role="presentation"
              onClick={() => setPledgeNotice(false)}
              alt="close"
              src={CloseSvg}
            />
            <div className='w-[80%] font-bold text-2xl text-[#02FFFF] text-center'>Pledge Notice</div>
            <div className='w-[80%] text-[#02FFFF] text-center mt-5'>*Your pledge amount is below the minimum amount, please pledge a minimum of 10 USDT*</div>
            <div className='flex justify-center item mt-5'>
              <div className='w-[220px] text-black bg-[#02FFFF] text-center rounded-full py-2 cursor-pointer mt-5' onClick={() => setPledgeNotice(false)}>
              Confirm
              </div>
            </div>
          </S.Content>
        </div>
        : null
      }
    </S.Mask>
  );
};

PledgePop.defaultProps = {
  closePop: () => {},
};

PledgePop.propTypes = {
  closePop: PropTypes.func,
};

export default PledgePop;
