import React from 'react';
import * as S from './styles';

const Loading = () => (
  <S.Container id="Technology">
    <S.GradientEffect />
    <div className="lds-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </S.Container>
);
export default Loading;
