import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CloseSvg from 'assets/images/x.svg';
import * as S from './styles';

const ClaimPop = ({ closePop }) => {
  const [amount, setAmount] = useState(1);

  const amountChange = (e) => {
    const { value } = e.target;
    if (value < 1) {
      setAmount(1);
    } else {
      setAmount(value);
    }
  };

  const t = '03D  ·  17H  ·  31M  ·  32S';

  return (
    <S.Mask className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center">
      <S.Content className="relative rounded-[10px] flex flex-col justify-start items-center p-[50px] md:p-[20px_10px] box-border">
        <img
          className="absolute w-[20px] h-[20px] top-[20px] right-[20px] cursor-pointer"
          role="presentation"
          onClick={() => closePop(false)}
          alt="close"
          src={CloseSvg}
        />
        <S.Title>Claimable Amount</S.Title>
        <S.Title
          style={{
            color: 'white',
            marginBottom: '30px',
          }}
        >
          1,273 USDT
        </S.Title>
        <S.Title>Claim Amount</S.Title>
        <div className="flex justify-between items-center mb-[30px]">
          <div className="flex justify-between items-center w-[441px] md:w-[290px] h-[59px] box-border p-[10px_10px_10px_20px] rounded-[50px] bg-[rgba(255,255,255,0.1)]">
            <S.InpuAmount
              type="number"
              className="flex-1 text-[rgba(255,255,255,.3)] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] bg-transparent outline-0"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => amountChange(e)}
            />
            <div
              role="presentation"
              onClick={() => setAmount(100)}
              className="flex justify-center items-center w-[59px] md:w-[52px] h-[39px] md:h-[34px] rounded-[50px] bg-[#02FFFF] text-[#0F1314] font-['Myriad_Pro'] font-[400] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] cursor-pointer"
            >
              max
            </div>
          </div>
          <span className="text-white text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] font-['Myriad_Pro'] font-[700]">
            USDT
          </span>
        </div>
        <S.Title>Pledge Countdown</S.Title>
        <div className="flex justify-between items-center mb-[10px]">
          <div className="flex justify-center items-start">
            <span className="text-white font-['Myriad_Pro'] font-[400] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px]">
              24/5
            </span>
            <div className="flex flex-col justify-start items-center w-[309px] md:w-[207px] ml-[20px] md:ml-[10px]">
              <div className="w-full h-[10px] rounded-[50px] bg-[#006161] relative">
                <div
                  className="absolute h-[10px] rounded-[50px] bg-[#02FFFF] left-0 top-0"
                  style={{
                    width: '50%',
                  }}
                >
                  {null}
                </div>
              </div>
              <span className="inline-block w-[207px] text-center text-[rgba(255,255,255,.5)] mt-[4px] text-[16px] leading-[19px] md:text-[12px] md:leading-[14px] font-bold">
                {t}
              </span>
            </div>
          </div>
          <span className="text-[#00FF75] font-['Myriad_Pro'] font-bold text-[16px] md:text-[12px] leading-[19px] md:leading-[14px]">
            +13% ($313)
          </span>
        </div>
        <div className="flex justify-between items-center mb-[10px]">
          <div className="flex justify-center items-start">
            <span className="text-white font-['Myriad_Pro'] font-[400] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px]">
              24/5
            </span>
            <div className="flex flex-col justify-start items-center w-[309px] md:w-[207px] ml-[20px] md:ml-[10px]">
              <div className="w-full h-[10px] rounded-[50px] bg-[#006161] relative">
                <div
                  className="absolute h-[10px] rounded-[50px] bg-[#02FFFF] left-0 top-0"
                  style={{
                    width: '50%',
                  }}
                >
                  {null}
                </div>
              </div>
              <span className="inline-block w-[207px] text-center text-[rgba(255,255,255,.5)] mt-[4px] text-[16px] leading-[19px] md:text-[12px] md:leading-[14px] font-bold">
                {t}
              </span>
            </div>
          </div>
          <span className="text-[#F50404] font-['Myriad_Pro'] font-bold text-[16px] md:text-[12px] leading-[19px] md:leading-[14px]">
            -13% ($313)
          </span>
        </div>
        <S.Estimate>+$1,626(23%)</S.Estimate>
        <div className="flex justify-center items-center h-[69px] md:h-[49px] rounded-[50px] bg-[#02FFFF] text-[#0F1314] text-[24px] md:text-[16px] leading-[29px] md:leading-[19px] font-['Myriad_Pro'] font-[600] cursor-pointer">
          Claim
        </div>
      </S.Content>
    </S.Mask>
  );
};

ClaimPop.defaultProps = {
  closePop: () => {},
};

ClaimPop.propTypes = {
  closePop: PropTypes.func,
};

export default ClaimPop;
