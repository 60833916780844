import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: localStorage.getItem('accessToken') || "",
    address: localStorage.getItem('address') || "",
    email: "",
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setId: (state, action) => {
      state.id = action.payload.id;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
      localStorage.setItem('accessToken', state.accessToken)
    },
    setAddress: (state, action) => {
      state.address = action.payload.address;
      localStorage.setItem('address', state.address)
    },
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setLogout: (state) => {
      state.accessToken = "";
      state.address = "";
      state.email = "";
      localStorage.removeItem('accessToken')
      localStorage.removeItem('address')
    },
  },
});

export const {
  setAccessToken,
  setEmail,
  setAddress,
  setLogout,
  setId,
} = userSlice.actions;

export default userSlice.reducer;
