import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import Home from 'pages';
import Incubator from 'pages/Incubator';
import Balance from 'pages/balancer';
import BalancerDetail from 'pages/BalancerDetail';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "",
    Component: App,
    children: [
      {
        path: "/",
        Component: Home,
      },
      {
        path: "/ai-incubator",
        Component: Incubator,
      },
      {
        path:"ai-marketplace",
        Component: Balance,
      },
      {
        path:"ai-vault",
        Component: BalancerDetail,
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
