import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CloseSvg from 'assets/images/x.svg';
import * as S from './styles';
import { withdrawList, walletBalances, withdraw } from 'api/tradegdt.js';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import Loading from 'components/Loading'
import depositWithdrawContractABI from '../../../../contracts/abi-deposit-withdraw-contract'
import { useSnackbar } from 'notistack';
import { useAccount, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'
import { ethers } from 'ethers';
import { floor } from 'lodash';

const ClaimPop = ({ closePop }) => {
  const userState = useSelector((state) => state.user);
  const [listLoading, setListLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [unpledgeLoading, setUnplegeLoading] = useState(false);
  const [list, setList] = useState([]);
  const [info, setInfo] = useState(null);
  const [withdrawConfirm, setWithdrawConfirm] = useState(false);
  const aiVaultContract = process.env.REACT_APP_AIVAULT_CONTRACT;
  const pusdContract = process.env.REACT_APP_PUSD_CONTRACT;
  const [claimArgs, setClaimArgs] = useState(null);
  const [claimItem, setClaimItem] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useAccount();
  const [amountStr, setAmountStr] = useState('');
  const [amount, setAmount] = useState(0);

  const amountChange = (e) => {
    const { value } = e.target;
    setAmountStr(value)
    setAmount(Number(value));
  };

  const getWithdrawList = async () => {
    try {
      const res = await withdrawList();
      let list = [];
      for (const item of res.data) {
        if (item.isClaim) {
          continue;
        }
        // item.matureDate = '2023-08-10T06:33:00.000Z'
        item['total_days'] = 10;
        let days = moment().diff(moment(item.matureDate).subtract(10, 'days'), 'days');
        item['current_days'] = days > 10 ? 10 : days;
        console.log(item['current_days']);
        item['pnl_ratio'] = ((item.pnl / item.principal) * 100).toFixed(2);
        list.push(item);
      }
      setList(list)
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
    
  }

  const getWalletBalances = async () => {
    try {
      const res = await walletBalances();
      let principal = 0;
      let pnl = 0;
      let compoundedAmount = 0;
  
      for (let i = 0; i < res.data.length; i++) {
        const currentElement = res.data.at(i);
  
        if (!currentElement) {
          continue;
        }
  
        if ('amount' in currentElement) {
          principal += Number(currentElement.amount);
          pnl += Number(currentElement.compoundedPnl);
          compoundedAmount =
            compoundedAmount +
            Number(currentElement.amount) +
            Number(currentElement.compoundedPnl);
        } else {
          if (!currentElement.profitFlushBackRatio) {
            throw new Error();
          }
  
          const temporaryPnl =
            compoundedAmount * Number(currentElement.pnlPerDeposit);
          pnl += Number(temporaryPnl);
          compoundedAmount += Number(temporaryPnl);
        }
      }
      if (compoundedAmount > 0) {
        setInfo({
          principal: principal,
          pnl: pnl.toFixed(6),
          pnl_ratio: ((pnl / principal) * 100).toFixed(2),
          amount: compoundedAmount,
        })
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  }

  const fetchData = () => {
    setListLoading(true);
    setInfo(null);
    setList([]);
    Promise.all([
      getWalletBalances(),
      getWithdrawList(),
    ]).then(() => {
      setListLoading(false);
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const { data: _claim, write: callClaim } = useContractWrite({
    address: aiVaultContract,
    abi: depositWithdrawContractABI,
    functionName: 'claim',
    mode: 'recklesslyUnprepared',
    onError: (err) => {
      setClaimLoading(false);
      const msg = err.details || err.cause?.reason || err.cause?.cause || err.message;
      enqueueSnackbar(msg, {
        variant: 'error'
      });
    },
    onSuccess: (data) => {
      
    },
  })
  const waitForClaimTransaction = useWaitForTransaction({
    scopeKey: `waittrx`,
    hash: _claim?.hash,
    onSuccess(data) {
      setClaimLoading(false);
      enqueueSnackbar('Claim successful', {
        variant: 'success'
      });
      list.splice(list.findIndex(x => x.id === claimItem.id), 1);
      setList([...list]);
    },
  })

  const onClaim = async (item) => {
    if (!item.sig) {
      return;
    }
    setClaimItem(item)
    if (claimLoading) return;
    setClaimLoading(true);
    callClaim({
      recklesslySetUnpreparedArgs: [
        item.raw,
        item.sig,
        ethers.utils.parseUnits(item.totalWithdraw, 6),
        ethers.utils.parseUnits(item.principal, 6),
      ]
    });
  }

  const onUnpledge = async () => {
    if (unpledgeLoading) return;
    if (amount <= 0) {
      enqueueSnackbar('Amount required', {
        variant: 'error'
      });
      return;
    }
    setUnplegeLoading(true);
    const res = await withdraw(amount);
    setWithdrawConfirm(false);
    setUnplegeLoading(false);
    fetchData();
  }

  return (
    <S.Mask className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center">
      <S.Content className="relative rounded-[10px] flex flex-col justify-start px-[50px] py-[30px] md:p-[30px_10px] box-border">
        <img
          className="absolute w-[20px] h-[20px] top-[20px] right-[20px] cursor-pointer"
          role="presentation"
          onClick={() => closePop(false)}
          alt="close"
          src={CloseSvg}
        />
        <S.Title>Pledge Detail</S.Title>
        {
          listLoading ?
          <div className='py-20'><Loading color='#FFF'></Loading></div> :
          <div>
            <div className='w-full text-white flex flex-col items-center gap-5 mt-5 sm:text-[12px]'>
            {
              list.map((item, index) => (
                <div key={index} className='w-full grid grid-cols-12 items-center gap-3 sm:gap-1'>
                  <div className='col-span-5 font-bold'>Pledge Amount : ${floor(Number(item.principal), 2).toLocaleString('en', { minimumFractionDigits: 2 })}</div>
                  <div className='col-span-3 font-bold'><span className={item.pnl >= 0 ? 'text-green-400' : 'text-red-400'}>{item.pnl_ratio >= 0 ? '+' : '-'}{item.pnl_ratio}% (${floor(Number(item.pnl), 2).toLocaleString('en', { minimumFractionDigits: 2 })})</span></div>
                  <div className={`col-span-2 text-black w-[92px] text-center py-1 rounded-full cursor-pointer sm:w-[52px] sm:text-[12px] ${item.current_days >= item.total_days ? 'bg-[#02FFFF]' : 'bg-[#B9BDBE]'}`} onClick={() => onClaim(item)}>
                    {
                      claimItem && claimItem.id == item.id && claimLoading ? <Loading color='#000'></Loading>
                      : 'Claim'
                    }
                  </div>
                  <div className="col-span-2 flex justify-center items-start">
                    <div className="flex flex-col justify-start items-center w-[40px]">
                      <div className="w-full h-[4px] rounded-[50px] bg-[#006161] relative">
                        <div
                          className="absolute h-[4px] rounded-[50px] bg-[#02FFFF] left-0 top-0"
                          style={{
                            width: `${(item.current_days / item.total_days) * 100}%`,
                          }}
                        >
                          {null}
                        </div>
                      </div>
                      <span className="text-[#02FFFF] mt-[2px] text-[12px]">
                        {item.current_days}D/{item.total_days}D
                      </span>
                    </div>
                  </div>
                </div>
              ))
            }
            {
              info ? 
              <div className='w-full grid grid-cols-12 items-center gap-3 sm:gap-1'>
                <div className='col-span-5 font-bold'>Pledge Amount : ${info.amount}</div>
                <div className='col-span-3 font-bold'><span className={info.pnl >= 0 ? 'text-green-400' : 'text-red-400'}>{info.pnl_ratio >= 0 ? '+' : '-'}{info.pnl_ratio}% (${info.pnl})</span></div>
                <div className={`col-span-2 text-black w-[92px] text-center py-1 rounded-full cursor-pointer sm:w-[72px] sm:text-[12px] bg-[#02FFFF]`} onClick={() => setWithdrawConfirm(true)}>
                  Unpledge
                </div>
              </div>
              : null
            }
            </div>
            {/* <div className='text-white font-bold text-[24px] mt-5'>
              <span>Estimate PNL </span>
              <span className='text-[#00ff75]'>+$100(30%)</span>
            </div> */}
          </div>
        }
      </S.Content>
      {
        withdrawConfirm ? 
        <div className='fixed top-0 left-0 w-screen h-screen flex justify-center items-center'>
          <div className='w-full h-full absolute inset-0 bg-black bg-opacity-60'></div>
          <S.Content className="w-[200px] !important relative rounded-[10px] flex flex-col justify-start items-center p-[50px] md:p-[20px_10px] box-border">
            <img
              className="absolute w-[20px] h-[20px] top-[20px] right-[20px] cursor-pointer"
              role="presentation"
              onClick={() => closePop(false)}
              alt="close"
              src={CloseSvg}
            />
            <S.Title>Unpledge Amount</S.Title>
            <div className="flex justify-between items-center mt-5">
              <div className="flex justify-between items-center w-[441px] md:w-[290px] h-[59px] box-border p-[10px_10px_10px_20px] rounded-[50px] bg-[rgba(255,255,255,0.1)]">
                <S.InpuAmount
                  type="number"
                  className="flex-1 text-[rgba(255,255,255,.3)] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] bg-transparent outline-0"
                  placeholder="Enter amount"
                  value={amountStr}
                  onChange={(e) => amountChange(e)}
                />
                <div
                  role="presentation"
                  onClick={() => {setAmount(info.amount);setAmountStr(info.amount.toString());}}
                  className="flex justify-center items-center px-2 h-[39px] md:h-[34px] rounded-[50px] bg-[#02FFFF] text-[#0F1314] font-['Myriad_Pro'] font-[400] text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] cursor-pointer"
                >
                  Max {floor(info.amount, 2).toLocaleString('en', { minimumFractionDigits: 2 })}
                </div>
              </div>
              <span className="text-white text-[16px] md:text-[12px] leading-[19px] md:leading-[14px] font-['Myriad_Pro'] font-[700] ml-2">
                USDT
              </span>
            </div>
            <div className='w-[80%] sm:w-full text-[#02FFFF] text-left mt-3 px-3'>Unpledge amount is only Claimable after 10 days.</div>
            <div className='w-[80%] sm:w-full flex gap-5 mt-3 px-3'>
              <div className='w-full text-white border-[1px] border-[#02FFFF] text-center rounded-full py-3 cursor-pointer mt-5' onClick={() => setWithdrawConfirm(false)}>Cancel</div>
              <div className='w-full text-black bg-[#02FFFF] text-center rounded-full py-3 cursor-pointer mt-5' onClick={() => onUnpledge()}>
                {
                  unpledgeLoading ? <Loading color='#FFF'></Loading>
                  : 'Confirm'
                }
              </div>
            </div>
          </S.Content>
        </div>
        : null
      }
    </S.Mask>
  );
};

ClaimPop.defaultProps = {
  closePop: () => {},
};

ClaimPop.propTypes = {
  closePop: PropTypes.func,
};

export default ClaimPop;
