import React from 'react';
import logoSvg from 'assets/images/logo_2.svg';
import * as S from './styles';

const Footer = () => (
  <S.Container>
    <img src={logoSvg} alt="logo" />
    <S.Copyright>2023 TradeGDT</S.Copyright>
  </S.Container>
);

export default Footer;
