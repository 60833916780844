import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const Y = 0;
const step = 40;
const IDs = [
  '#tradeGDT',
  '#Economy',
  '#Technology',
  '#Incubator',
  '#Balancer',
  '#NFT',
  '#Marketplace',
  '#Team',
  '#Partner',
];

const Pagination = ({ isLeft, align, index }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <S.Container onClick={() => setCollapsed(!collapsed)} align={align} isLeft={isLeft}>
      {IDs.map((id, i) => (
        <S.Circle key={id} href={id} top={`${Y + i * step}px`} selected={i === index} />
      ))}
      <S.Line height={`${step * (IDs.length - 1)}px`} />
    </S.Container>
  );
};

Pagination.defaultProps = {
  isLeft: true,
  align: 'center',
  index: 0,
};

Pagination.propTypes = {
  isLeft: PropTypes.bool,
  align: PropTypes.string,
  index: PropTypes.number,
};

export default Pagination;
