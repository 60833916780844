import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useAccount, useSignMessage } from 'wagmi';
import * as S from './styles.js';
import logoSvg from 'assets/images/logo_3.svg';
import { walletNonce, web3Login } from 'api/traderdao.js';
import { login } from 'api/tradegdt.js';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'react-redux'
import { setAccessToken, setAddress } from "../../../../redux/userSlice.js";

const SignPop = ({changeShow, logoutHandler}) => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const messageTemplate = 'Welcome to TradeGDT!\n\nPlease sign this message for verification.\nThere would not be any charges for this action.\n\nWallet address:\n${address}\n\nNonce:\n${nonce}';
  const { address, isConnected } = useAccount();

  const { signMessageAsync } = useSignMessage();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'unset';
    }
  })
  
  const CancelHandler = () => {
    logoutHandler()
    changeShow(false)
  }

  const signHandler = async() => {
    if(!isConnected && !address){
      return enqueueSnackbar('wallet disconnect', {
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
    if (loading) {
      return;
    }
    try{
      setLoading(true);
      const walletNonceRes = await walletNonce(address)
      const message = messageTemplate.replace('${address}', address).replace('${nonce}', walletNonceRes.WalletNonce);
      const signature = await signMessageAsync({ message })
      const web3loginRes = await web3Login(message, signature)
      console.log('web3loginRes', web3loginRes);
      const res = await login(web3loginRes.AccessToken.access_token);
      console.log('res', res);
      dispatch(setAddress({address: address}))
      dispatch(setAccessToken({accessToken: res.token.accessToken}))
    }catch(error){
      enqueueSnackbar(error, {
        variant: 'error'
      });
      CancelHandler()
    } finally {
      setLoading(false);
    }
  }

  return (
    <S.Mask>
      <S.Pop>
        <S.Logo src={logoSvg} />
        <span className="text-black text-[28px] leading-[34px] font-bold font-['Myriad_Pro'] mt-3" >Welcome to TradeGDT</span>
        <div className="w-full text-black flex flex-col justify-around items-center mt-3">
          <div className='text-center'>By connecting your wallet and using TradeGDT, you need to sign message.</div>
          <div className="w-full flex justify-around items-center mt-8" >
            <div className="btn bg-black bg-opacity-5" onClick={() => CancelHandler()} >Cancel</div>
            <div className="btn text-white" onClick={() => signHandler()} >
              {
                loading ? 
                <Loading color='#FFFFFF'></Loading>
                : 'Sign'
              }
            </div>
          </div>
        </div>
      </S.Pop>
    </S.Mask>
  );
};

SignPop.defaultProps = {
  changeShow: () => {},
};

SignPop.propTypes = {
  changeShow: PropTypes.func,
};

export default SignPop;