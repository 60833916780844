import styled from 'styled-components';
import DetailBg from 'assets/images/balancer-bg-1.png';

export const Container = styled.div`
  background-color: #0f1314;
  background-image: url(${DetailBg});
  background-repeat: no-repeat;
  background-position: top right -291px;
  background-size: 651px 736px;
  padding-top: 15vh;
  padding-bottom: 10vh;
  .tvl-border {
    background: linear-gradient(190deg, rgba(2, 255, 255, 1), rgba(2, 255, 255, 0.2));
    padding: 1px;
    border-radius: 10px;
  }
  .tvl-bg {
    position: relative;
    border-radius: 10px;
    background: linear-gradient(171deg, rgb(0, 0, 0) 20.31%, rgb(50, 50, 50) 49.48%, rgba(0,0,0) 81.25%);
  }
  @media screen and (max-width: 899px) {
    background-image: none;
  }
`;

export const Filter = styled.div`
  background: #02ffff;
  padding: 3px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterItem = styled.span`
  display: inline-block;
  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 50px;
  color: #0f1314;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  &:nth-last-of-type(1) {
    margin-right: 0;
  }
  &[data-active='true'] {
    color: #fff;
    background: #0f1314;
    box-shadow: inset 0px -3px 0px rgba(255, 255, 255, 0.5);
  }
`;

export const GhostBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 69px;
  border-radius: 50px;
  border: 1px solid #02ffff;
  margin-bottom: 20px;
  cursor: pointer;
  @media screen and (max-width: 899px) {
    width: 200px;
    height: 49px;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const GoodsBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 69px;
  border-radius: 50px;
  color: #0f1314;
  font-size: 24px;
  line-height: 29px;
  font-family: 'Myriad Pro';
  font-weight: 600;
  margin-bottom: 20px;
  background: #02ffff;
  cursor: pointer;
  @media screen and (max-width: 899px) {
    width: 200px;
    height: 49px;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const ChartContainer = styled.div`
  width: 550px;
  box-sizing: border-box;
  padding: 30px 32px;
  border-radius: 10px;
  margin-bottom: 30px;
  background: linear-gradient(
      149.41deg,
      rgba(255, 255, 255, 0) 31.61%,
      rgba(255, 255, 255, 0.1) 49.26%,
      rgba(255, 255, 255, 0) 68.49%
    ),
    rgba(255, 255, 255, 0.05);
  @media screen and (max-width: 1029px) {
    width: 350px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const VaultContainer = styled.div`
  width: 550px;
  margin-bottom: 30px;
  @media screen and (max-width: 1029px) {
    width: 350px;
  }
`;

export const Tag = styled.div`
  width: 100px;
  cursor: pointer;
  &[data-active='true'] {
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 110px;
      height: 3px;
      bottom: -11.5px;
      left: 0;
      background-color: #02ffff;
    }
  }
  @media screen and (max-width: 1029px) {
    width: 70px;
    &[data-active='true'] {
      &::before {
        width: 80px;
      }
    }
  }
`;

export const GridDiv = styled.div`
  margin-top: 10px;
  grid-template-columns:
    minmax(109px, 3fr)
    minmax(20px, 1fr)
    minmax(81px, 2fr)
    minmax(81px, 2fr)
    minmax(81px, 2fr);
  @media screen and (max-width: 1029px) {
    column-gap: 8px;
    grid-template-columns:
      88px
      30px
      60px
      60px
      60px;
  }
`;
