import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 844px;
  // background: #002429;
  padding: 100px;
  display: flex;
  justify-content: center;
  position: relative;
  // overflow: hidden;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  .button-bg {
    background: #02ffff;
    box-shadow: 0px 0px 20px #02ffff;
  }
`;

export const Bg = styled.img`
  object-fit: cover;
  object-position: 85% 85%;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  @media screen and (max-width: 899px) {
    
  }
`;

export const InnerContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  z-index: 1;
  position: relative;
  color: #02ffff;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

export const CaptionContainer = styled.div`
  position: absolute;
  bottom: 20%;
  left: 0;
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 36px;
    position: relative;
    left: unset;
    bottom: unset;
  }
`;

export const OwnerContainer = styled.div`
  position: absolute;
  // width: 35%;
  width: 460px;
  left: 0;
  top: calc(50% - 633.71px / 2 + 76px);
  background: rgba(15, 19, 20, 0.7);
  border: 1px solid #02ffff;
  box-shadow: inset 0px 0px 100px rgba(2, 255, 255, 0.35);
  backdrop-filter: blur(10px);
  border-radius: 50px;
  padding: 50px 60px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    // position: relative;
    // left: unset;
    // bottom: unset;
    padding: 30px 20px;
    width: 100%;
    margin-top: 250px;
    border-radius: 20px;
  }
  .box-bg {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #02ffff;
    box-shadow: 0px 0px 15px rgba(2, 255, 255, 0.5);
  }
`;
export const AlertContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  .warning {
    color: #fcff5c !important;
    border: 1px solid #fcff5c !important;
    box-shadow: inset 0px 0px 100px rgba(252, 255, 92, 0.35) !important;
  }
`;
export const AlertMask = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
`;
export const AlertContent = styled.div`
  width: 460px;
  background: rgba(15, 19, 20, 0.7);
  border: 1px solid #02ffff;
  box-shadow: inset 0px 0px 100px rgba(2, 255, 255, 0.35);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 30px 80px;
  color: #02ffff;
  overflow: hidden;
  position: relative;
  .close-warning {
    border: 1px solid #fcff5c;
    box-shadow: inset 0px 0px 30px rgba(252, 255, 92, 0.35);
  }
  @media screen and (max-width: 768px) {
    width: 92%;
  }
`;
export const AlertClose = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  width: 60px;
  height: 40px;
  background: rgba(15, 19, 20, 0.7);
  border: 1px solid #02ffff;
  border-top: none;
  box-shadow: inset 0px 0px 30px rgba(2, 255, 255, 0.35);
  backdrop-filter: blur(10px);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const div = styled.div``;
