import axios from 'axios';
import { store } from "../redux/store";

const service = axios.create({
  baseURL: process.env.REACT_APP_TRADEGDT_API,
  timeout: 60000,
})

service.interceptors.request.use(
  config => {
      const userState = store.getState().user;
      console.log(userState);
      if (userState.accessToken) {
        config.headers["Authorization"] = 'Bearer ' + userState.accessToken
      }
      return config
  },
  error => {
      console.log('error',error);
      let message = error?.response?.data?.Message ?? error.message
      Promise.reject(message)
  }
)

service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
      console.log('error',error)
      let message = error?.response?.data?.Message ?? error.message
      return Promise.reject(message)
  }
)

export default service;

export function login(accessToken){
  return service({
    url: `/v1/auth/login`,
    method: 'POST',
    data: {
      accessToken
    }
  })
}

export function me(){
  return service({
    url: `/v1/auth/me`,
    method: 'GET',
  })
}

export function stateConfigInfo(){
  return service({
    url: `/v1/state-configs/info`,
    method: 'GET',
  })
}

export function profitAndLoss(page){
  return service({
    url: `/v1/profit-and-loss`,
    method: 'GET',
    params: {
      page
    }
  })
}

export function balanceJournals(){
  return service({
    url: `/v1/balance-journals`,
    method: 'GET',
  })
}

export function walletBalances(){
  return service({
    url: `/v1/wallet-balances`,
    method: 'GET',
  })
}

export function withdrawList(page = 1, take = 100){
  return service({
    url: `/v1/withdraws`,
    method: 'GET',
    params: {
      page,
      take,
    }
  })
}

export function withdraw(amount){
  return service({
    url: `/v1/withdraws`,
    method: 'POST',
    data: {
      withdrawAmount: amount
    }
  })
}