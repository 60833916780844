import axios from 'axios';

const service = axios.create({
  baseURL: process.env.REACT_APP_TRADERDAO_API,
  timeout: 60000,
})

service.interceptors.request.use(
  config => {
      return config
  },
  error => {
      console.log('error',error);
      let message = error?.response?.data?.Message ?? error.message
      return Promise.reject(message)
  }
)

service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
      console.log('error',error)
      let message = error?.response?.data?.Message ?? error.message
      return Promise.reject(message)
  }
)

export default service;

export function walletNonce(address){
  return service({
    url: `/api/customer/WalletNonce`,
    method: 'GET',
    params: {
      address: address
    }
  })
}

export function web3Login(message, signature){
  return service({
    url: `/api/customer/Web3Login`,
    method: 'POST',
    data: {
      message,
      signature,
    }
  })
}