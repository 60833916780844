import React, { useState, useRef, useEffect, useCallback, reactive } from 'react';
import * as echarts from 'echarts';
import NoticeSvg from 'assets/images/balancer-notice.svg';
import DownSvg from 'assets/images/down.svg';
import Incubator from 'assets/images/balancer-box.png';
import BitSvg from 'assets/images/Bit.svg';
import ClaimPop from './components/ClaimPop';
import PledgePop from './components/PledgePop';
import PledgeDetailPop from './components/PledgeDetailPop';
import * as S from './styles';
import { stateConfigInfo, profitAndLoss, me } from 'api/tradegdt.js';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'react-redux'
import { setId } from "../../redux/userSlice";
import moment from 'moment';
import { useAccount, open } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { useSnackbar } from 'notistack';
import { floor } from 'lodash';
import ReactPaginate from 'react-paginate';
import prevpageicon from "assets/images/prevpage.svg"
import nextpageicon from "assets/images/nextpage.svg"
import incubator_blue from 'assets/videos/incubator_blue.mp4';

const BalancerDetail = (props) => {
  const [pledgeShow, setPledgeShow] = useState(false);
  const [pledgeDetailShow, setPledgeDetailShow] = useState(false);
  const [info, setInfo] = useState(null);
  const pageSize = 10;
  const [pageCount, setPageCount] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { isConnected } = useAccount();
  const { open: openWeb3Modal } = useWeb3Modal();
  const { enqueueSnackbar } = useSnackbar();

  const topTimeList = ['24H', '7D', '30D'];
  const [topIndex, setTopIndex] = useState(0);
  const topTimeFilter = (index) => {
    setTopIndex(index);
  };
  const bottomTimeList = ['1m', '6m', '1y'];
  const [bottomIndex, setBottomIndex] = useState(0);
  const bottomTimeFilter = (index) => {
    setBottomIndex(index);
  };
  const [tvl, setTvl] = useState('--');
  const [valueList, setValueList] = useState([
    {
      key: 'Trading Volume',
      value: '--',
    },
    {
      key: 'Pledge Amount',
      value: '--',
    },
    {
      key: 'Tx Count',
      value: '--',
    },
    {
      key: 'Sharpe Ratio',
      value: '--',
      ext: '%',
    },
    {
      key: 'PNL',
      start: '$',
      value: '--',
    },
    {
      key: 'APR%',
      value: '--',
      ext: '%',
    },
    {
      key: 'Drawdown%',
      value: '--',
    },
  ]);

  const tagList = ['Tx Profit'];

  const [tagIndex, setTagIndex] = useState(0);

  const tagChange = (index) => {
    // setTagIndex(index);
  };

  const [thList, setThList] = useState([
    {
      name: 'Time',
    },
    {
      name: 'Token',
    },
    {
      name: 'Profit',
      filter: 1,
    },
    {
      name: 'Entry',
      filter: 2,
    },
    {
      name: 'Take Profit',
      filter: 1,
    },
  ]);

  const changeFilter = (index) => {
    if (!thList[index].filter) return null;
    thList[index].filter = thList[index].filter === 1 ? 2 : 1;
    window.console.log('挣到了？', thList[index].filter);
    return setThList([...thList]);
  };

  const [transitionList, setTransitionList] = useState([]);

  const equityRef = useRef(null);
  const dailyRef = useRef(null);

  let equityChart = null;
  const equityOption = {
    tooltip: {},
    legend: {
      show: true,
      data: [
        {
          name: 'Cumulative Profit',
          icon: 'rect',
        },
        {
          name: 'Top Profit',
          icon: 'rect',
        },
        {
          name: 'DD',
          icon: 'rect',
        },
        {
          name: 'Market Price',
          icon: 'rect',
        },
      ],
      itemWidth: 15,
      itemHeight: 15,
      borderRadius: 0,
      textStyle: {
        color: '#fff',
        fontFamily: 'Myriad Pro',
      },
    },
    xAxis: {
      type: 'value',
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
        onZero: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255,0.5)',
        },
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.5)',
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
        onZero: false,
      },
      axisLabel: {
        color: 'rgba(255,255,255,.5)',
      },
    },
    series: [
      {
        name: 'Cumulative Profit',
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'line',
      },
      {
        name: 'Top Profit',
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'line',
      },
      {
        name: 'DD',
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'line',
      },
      {
        name: 'Market Price',
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'line',
      },
    ],
    media: [
      {
        option: {
          legend: {
            orient: 'horizontal',
            bottom: 10,
            itemGap: 25,
            textStyle: {
              fontSize: 16,
            },
            width: 500,
          },
        },
      },
      {
        query: {
          maxWidth: 330,
        },
        option: {
          legend: {
            orient: 'horizontal',
            bottom: -10,
            itemGap: 20,
            width: 330,
            textStyle: {
              fontSize: 12,
            },
          },
        },
      },
    ],
  };
  let dailyChart = null;
  const dailyOpiton = {
    tooltip: {},
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      name: 'Supply APR',
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.5)',
          type: 'dashed',
        },
      },
      axisLabel: {
        color: 'rgba(255,255,255,.5)',
      },
      nameLocation: 'end',
      nameGap: 15,
      nameTextStyle: {
        color: '#FFFFFF',
      },
    },
    series: [
      {
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'bar',
      },
    ],
  };

  const getUserInfo = async () => {
    const res = await me();
    if (res.statusCode == 200) {
      dispatch(setId({id: res.id}))
    }
  }
  
  useEffect(() => {
    if (userState.accessToken && !userState.id) {
      getUserInfo();
    }
  }, [userState]);

  useEffect(() => {
    if (equityRef.current && !equityChart) {
      equityChart = echarts.init(equityRef.current);
      equityChart.setOption(equityOption);
    }
    if (dailyRef.current && !dailyChart) {
      dailyChart = echarts.init(dailyRef.current);
      dailyChart.setOption(dailyOpiton);
    }
  }, [equityRef, dailyRef]);

  const handleWindowResize = useCallback(() => {
    if (equityRef.current && equityChart) equityChart?.resize();
    if (dailyRef.current && dailyChart) dailyChart?.resize();
  }, [equityRef, dailyRef]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const getStateConfigInfo = async () => {
    try {
      const res = await stateConfigInfo()
      valueList[0].value = '$ ' + floor(Number(res.totalTradingVol), 2).toLocaleString('en', { minimumFractionDigits: 2 });
      valueList[1].value = '$ ' + floor(Number(res.userPledgeAmount), 2).toLocaleString('en', { minimumFractionDigits: 2 });
      valueList[2].value = res.trxCount;
      valueList[3].value = floor(Number(res.sharpeRatio), 2).toLocaleString('en', { minimumFractionDigits: 2 });
      valueList[4].value = '$ ' + floor(Number(res.totalPnl), 2).toLocaleString('en', { minimumFractionDigits: 2 });
      valueList[5].value = floor(Number(res.apr), 2).toLocaleString('en', { minimumFractionDigits: 2 }) + '%';
      valueList[6].value = floor(Number(res.drawdownPercentage), 2).toLocaleString('en', { minimumFractionDigits: 2 }) + '%';
      setValueList(valueList)
      setTvl('$ ' + floor(Number(res.tvl), 2).toLocaleString('en', { minimumFractionDigits: 2 }))
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  }

  const getProfitAndLoss = async (page) => {
    try {
      setListLoading(true)
      const res = await profitAndLoss(page)
      const list = res.data.map(e => {
        e.time = moment(e.time).format('YYYY-MM-DD HH:mm:ss')
        return e;
      })
      setTransitionList(list)
      setPageCount(res.meta.pageCount)
      setListLoading(false)
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
    
  }

  useEffect(() => {
    if (userState.accessToken) {
      getStateConfigInfo()
      getProfitAndLoss(1)
    }
  }, [userState.accessToken]);

  

  return (
    <S.Container
      id="Detail"
      className="flex justify-center items-start gap-40 lg:gap-10 lg:flex-col lg:justify-start lg:items-center w-full overflow-hidden"
    >
      {pledgeShow ? <PledgePop closePop={setPledgeShow} /> : null}
      {pledgeDetailShow ? <PledgeDetailPop closePop={setPledgeDetailShow} /> : null}
      <div className="flex flex-col justify-start items-center">
        <span className="text-[#00FF75] text-center text-[36px] leading-[43px] font-[700] font-['Myriad_Pro'] md:text-[20px] md:leading-[24px]">
          Specimen X01
        </span>
        {/* <img
          className="w-[500px] h-[600px] my-[10px] object-contain object-top mb-5 md:w-[calc(500px*0.7)] md:h-[calc(600px*0.7)]"
          alt="incuabox"
          src={Incubator}
        /> */}
        <video autoPlay loop muted playsInline className='w-[550px] h-[650px] my-[10px] object-contain object-top mb-5 md:w-[350px)] md:h-[420px]'>
          <source src={incubator_blue} type="video/mp4" />
        </video>
        {
          !isConnected ?
          <S.GhostBtn role="presentation" onClick={openWeb3Modal}>
            <span className="text-white font-[600] text-[24px] leading-[29px] mb-0.5 md:text-[16px] md:leading-[19px]">
              Connect Wallet
            </span>
          </S.GhostBtn>
        :
        <div>
          <S.GhostBtn role="presentation" onClick={() => setPledgeShow(true)}>
            <span className="text-white font-[600] text-[24px] leading-[29px] mb-0.5 md:text-[16px] md:leading-[19px]">
              Pledge
            </span>
            <span className="text-white font-[400] text-[12px] leading-[14px] md:text-[8px] md:leading-[10px]">
              (USDT)
            </span>
          </S.GhostBtn>
          <S.GoodsBtn
            role="presentation"
            onClick={() => setPledgeDetailShow(true)}
            style={{
              mixBlendMode: 'luminosity',
              backdropFilter: 'blur(5px)',
            }}
          >
            Claim
          </S.GoodsBtn>
        </div>
        }
      </div>
      <div className="flex flex-col justify-start items-center">
        <div className="flex flex-col justify-start items-center w-[550px] lg:w-[350px]">
          <S.VaultContainer>
            <span className="inline-block w-full mb-[20px] text-[#02FFFF] font-[600] text-[24px] leading-[29px] lg:text-[20px] lg:leading-[19px] lg:text-center">
            Specimen X01 Overview
            </span>
            <div className='tvl-border'>
              <div className="tvl-bg py-5 px-5 flex flex-col justify-between items-start">
                <span className="text-[rgba(255,255,255,0.5)] font-[700] font-['Myriad_Pro'] text-[16px] leading-[19px] lg:text-[12px] lg:leading-[14px]">
                TVL (USDT)
                </span>
                <span className="text-white font-['Myriad_Pro'] font-[700] mt-[15px] text-[28px] leading-[19px] lg:text-[20px] lg:leading-[14px]">
                {tvl}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-[30px] gap-y-[20px] mt-[30px] mb-0 w-[550px] lg:w-[350px]">
              {valueList.map((vItem, vIndex) => (
                <div
                  key={vItem.key}
                  className="flex flex-col justify-between items-start"
                >
                  <span className="text-[rgba(255,255,255,0.5)] font-[700] font-['Myriad_Pro'] text-[16px] leading-[19px] lg:text-[12px] lg:leading-[14px]">
                    {vItem.key}
                  </span>
                  <span className="text-white font-['Myriad_Pro'] font-[700] mt-[10px] text-[16px] leading-[19px] lg:text-[16px] lg:leading-[14px]">
                    {vItem.value}
                  </span>
                </div>
              ))}
            </div>
          </S.VaultContainer>
          {/* <S.ChartContainer className="mb-[30px]">
            <span className="inline-block w-[486px] mb-[20px] text-[#02FFFF] font-[600] text-[24px] leading-[29px] dash:text-[16px] dash:leading-[19px]">
              Equity Curve
            </span>
            <div
              className="w-[491px] h-[476px] lg:w-[330px] lg:h-[319px]"
              ref={(eRef) => {
                equityRef.current = eRef;
              }}
            >
              {null}
            </div>
          </S.ChartContainer> */}
        </div>
        <div className="flex flex-col justify-start items-center w-[550px] lg:w-[350px]">
        <S.ChartContainer className="mb-[30px]">
          <span className="inline-block w-[500px] lg:w-[330px] mb-[30px] text-[#02FFFF] font-[600] text-[24px] leading-[29px] lg:text-[16px] lg:leading-[19px]">
            Transaction PNL
          </span>
          <div className="flex justify-start items-center w-[500px] lg:w-[330px] overflow-hidden pb-[10px] border-b border-[rgba(255,255,255,.5)]">
            {tagList.map((taItem, taIndex) => (
              <S.Tag
                key={taItem}
                onClick={() => tagChange(taIndex)}
                data-active={tagIndex === taIndex}
                className="relative flex items-center mr-[30px]"
              >
                <span
                  style={{
                    opacity: tagIndex === taIndex ? 1 : 0.5,
                  }}
                  className="font-['Myriad_Pro'] font-[700] text-[16px] leading-[19px] text-white mr-[10px] lg:text-[12px] lg:leading-[14px] whitespace-nowrap"
                >
                  {taItem}
                </span>
                <img
                  style={{
                    opacity: tagIndex === taIndex ? 1 : 0.5,
                  }}
                  alt="notice"
                  src={NoticeSvg}
                />
              </S.Tag>
            ))}
          </div>
          <S.GridDiv className="relative w-[500px] lg:w-[330px] grid items-center gap-y-[15px] gap-x-[27px]">
            {thList.map((thItem, thIndex) => (
              <div
                key={thItem.name}
                onClick={() => changeFilter(thIndex)}
                role="presentation"
                onKeyDown={() => {}}
                className={`w-full flex items-center cursor-pointer ${
                  thIndex <= 1
                    ? 'justify-start'
                    : thIndex === 4
                    ? 'justify-end'
                    : 'justify-center'
                }`}
              >
                <span className="text-[rgba(255,255,255,.5)] mr-[2px] lg:text-[12px] lg:leading-[14px]">
                  {thItem.name}
                </span>
                {thItem.filter ? (
                  <img
                    className={`w-[12px] h-[4px] opacity-50 ${
                      thItem.filter === 1 ? 'rotate-180' : null
                    }`}
                    alt="down"
                    src={DownSvg}
                  />
                ) : null}
              </div>
            ))}
            {/* {
              listLoading ? 
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center p-20">
                <Loading color='#FFFFFF'></Loading>
              </div> : null
            } */}
            {transitionList.map((trItem, trIndex) => (
              <>
                <div className="flex flex-col justify-between items-start" key={trItem}>
                  <span className="inline-block beak-all font-['Myriad_Pro'] text-white text-[12px] leading-[14px] lg:text-[10px] lg:leading-[12px]">
                    {trItem.time}
                  </span>
                  {/* <span className="inline-block beak-all font-['Myriad_Pro'] whitespace-nowrap text-white text-[16px] leading-[19px] lg:text-[12px] lg:leading-[14px]">
                    0xf7b...7c4c0
                  </span> */}
                </div>
                <span
                  className="inline-block font-['Myriad_Pro'] text-center text-white text-[12px] leading-[19px] lg:text-[12px] lg:leading-[14px] break-all"
                  key={trItem}
                >
                  {trItem.token}
                </span>
                {/* <img className="h-[20px] w-[20px]" key={trItem} alt="bit" src={BitSvg} /> */}
                <span
                  className="inline-block font-['Myriad_Pro'] text-center text-white text-[16px] leading-[19px] lg:text-[12px] lg:leading-[14px] break-all"
                  key={trItem}
                >
                  {floor(Number(trItem.pnl), 2).toLocaleString('en', { minimumFractionDigits: 2 })}
                </span>
                <span
                  className="inline-block font-['Myriad_Pro'] text-center text-white text-[16px] leading-[19px] lg:text-[12px] lg:leading-[14px] break-all"
                  key={trItem}
                >
                  {floor(Number(trItem.cost), 2).toLocaleString('en', { minimumFractionDigits: 2 })}
                </span>
                <span
                  className="inline-block font-['Myriad_Pro'] text-right text-white text-[16px] leading-[19px] lg:text-[12px] lg:leading-[14px] break-all"
                  key={trItem}
                >
                  {floor(Number(trItem.revenue), 2).toLocaleString('en', { minimumFractionDigits: 2 })}
                </span>
              </>
            ))}
          </S.GridDiv>
          {
            pageCount > 0 ?
            <ReactPaginate
              breakLabel="..."
              previousLabel={ <img className="w-[12px] h-[12px]" src={prevpageicon} /> }
              nextLabel={ <img className="w-[12px] h-[12px]" src={nextpageicon} /> }
              onPageChange={(page)=> getProfitAndLoss(page.selected+1)}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              renderOnZeroPageCount={(props)=> null}
              containerClassName="flex items-center justify-center mt-[20px]"
              pageClassName="h-[35px] w-[35px] flex items-center justify-center text-[rgba(255,255,255,.5)] text-sm cursor-pointer leading-5 rounded-lg"
              pageLinkClassName='h-[35px] w-[35px] flex items-center justify-center'
              activeClassName='bg-[#1F353B]'
              activeLinkClassName='text-green'
              previousClassName='h-[35px] w-[35px]'
              previousLinkClassName='h-full w-full flex items-center justify-center'
              nextClassName='h-[35px] w-[35px]'
              nextLinkClassName='h-full w-full flex items-center justify-center'
              disabledLinkClassName='force-not-allowed opacity-50'
            />
            : null
          }
          
        </S.ChartContainer>
          {/* <S.ChartContainer className="mb-[30px]">
            <div className="flex justify-between items-center w-[491px] dash:w-[330px] mb-[23px]">
              <span className="inline-block w-[486px] text-[#02FFFF] font-[600] text-[24px] leading-[29px] dash:w-fit dash:text-[16px] dash:leading-[19px]">
                Daily Return %
              </span>
              <S.Filter className="dash:scale-75">
                {bottomTimeList.map((bItem, bIndex) => (
                  <S.FilterItem
                    key={bItem}
                    onClick={() => bottomTimeFilter(bIndex)}
                    data-active={bIndex === bottomIndex}
                  >
                    {bItem}
                  </S.FilterItem>
                ))}
              </S.Filter>
            </div>
            <div
              className="w-[491px] h-[300px] lg:w-[330px]"
              ref={(dRef) => {
                dailyRef.current = dRef;
              }}
            />
          </S.ChartContainer> */}
        </div>
      </div>
    </S.Container>
  );
};

export default BalancerDetail;
