import styled from 'styled-components';
import background from 'assets/images/balancer-bg-2.png';

export const Container = styled.div`
  background: #0f1314;
`;

export const Detail = styled.div``;

export const DetailBackground = styled.img`
  height: 736px;
  width: 651px;
  max-width: 651px;
`;

export const Filter = styled.div`
  background: #02ffff;
  padding: 3px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterItem = styled.span`
  display: inline-block;
  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 50px;
  color: #0f1314;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  &:nth-last-of-type(1) {
    margin-right: 0;
  }
  &[data-active='true'] {
    color: #fff;
    background: #0f1314;
    box-shadow: inset 0px -3px 0px rgba(255, 255, 255, 0.5);
  }
`;

export const Stream = styled.div`
  border-radius: 10px;
  background: linear-gradient(
      149.41deg,
      rgba(255, 255, 255, 0) 31.61%,
      rgba(255, 255, 255, 0.1) 49.26%,
      rgba(255, 255, 255, 0) 68.49%
    ),
    rgba(255, 255, 255, 0.05);
`;

export const StreamLeft = styled.div`
  position: relative;
  margin-right: 20px;
  &::before {
    content: '';
    position: absolute;
    right: -20px;
    top: 11px;
    width: 1px;
    height: 25px;
    background: rgba(255, 255, 255, 0.3);
  }
  @media screen and (max-width: 899px) {
    margin-right: 10px;
    &::before {
      right: -10px;
      top: 6px;
    }
  }
`;

export const StreamIcon = styled.img`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 10px;
  @media screen and (max-width: 899px) {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    margin-right: 5px;
  }
`;

export const IncubatorShadow = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(2, 255, 255, 0.35) 0%,
    rgba(2, 255, 255, 0) 100%
  );
`;

export const TVL = styled.div`
  width: 404px;
  height: 112px;
  border-radius: 10px;
  padding: 20px;
  background: linear-gradient(
    149.41deg,
    rgba(255, 255, 255, 0) 31.61%,
    rgba(255, 255, 255, 0.1) 49.26%,
    rgba(255, 255, 255, 0) 68.49%
  );
  border: 2px solid #02ffff;
  box-sizing: border-box;
  @media screen and (max-width: 899px) {
    width: 350px;
    height: 88px;
  }
`;

export const PerformanceBox = styled.div`
  background: linear-gradient(
    149.41deg,
    rgba(255, 255, 255, 0) 31.61%,
    rgba(255, 255, 255, 0.1) 49.26%,
    rgba(255, 255, 255, 0) 68.49%
  );
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #02ffff;
  margin-bottom: 20px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;

export const ChartWrapper = styled.div`
  border-radius: 10px;
  width: 851px;
  padding: 20px 30px;
  background: linear-gradient(
      149.41deg,
      rgba(255, 255, 255, 0) 31.61%,
      rgba(255, 255, 255, 0.1) 49.26%,
      rgba(255, 255, 255, 0) 68.49%
    ),
    rgba(255, 255, 255, 0.05);
  @media screen and (max-width: 1029pxpx) {
    width: 401px;
  }
  @media screen and (max-width: 899px) {
    width: 350px;
    padding: 20px 10px;
  }
`;

export const Goods = styled.div`
  margin-top: 131px;
  margin-bottom: 100px;
  background: no-repeat center/cover url(${background});
`;

export const GoodsTitle = styled.span`
  color: #02ffff;
  font-size: 64px;
  line-height: 77px;
  font-family: 'Myriad Pro';
  font-weight: 700;
  position: relative;
  margin-bottom: 50px;
  // &::after {
  //   content: 'Learning';
  //   color: #0f1314;
  //   font-family: 'Myriad Pro';
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 19px;
  //   position: absolute;
  //   right: 0;
  //   top: -29px;
  //   height: 29px;
  //   padding: 5px 15px;
  //   background: #fff502;
  //   border-radius: 50px;
  // }
  @media screen and (max-width: 899px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const GhostBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 69px;
  border-radius: 50px;
  border: 1px solid #02ffff;
  margin-bottom: 20px;
  cursor: pointer;
  @media screen and (max-width: 899px) {
    width: 200px;
    height: 49px;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const GoodsBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 69px;
  border-radius: 50px;
  color: #0f1314;
  font-size: 24px;
  line-height: 29px;
  font-family: 'Myriad Pro';
  font-weight: 600;
  margin-bottom: 20px;
  background: #02ffff;
  cursor: pointer;
  @media screen and (max-width: 899px) {
    width: 200px;
    height: 49px;
    font-size: 16px;
    line-height: 19px;
  }
`;
