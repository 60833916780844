import styled from 'styled-components';

export const Container = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #0f1314;
  border-top: 1px solid ${(props) => props.theme.main};
  @media screen and (max-width: 1280px) {
    padding: 50px 0;
  }
`;

export const Copyright = styled.span`
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 69px;
  color: #ffffff;
  font-family: 'Open Sans';
  @media screen and (max-width: 1280px) {
    font-size: 15px;
  }
`;
