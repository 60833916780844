import React from 'react';
import loading from 'assets/images/loading.svg';
import * as S from './styles';

const styles = {
  width: '20px',
  height: '20px',
  stroke: '#FFFFFF',
}

const Loading = ({ color = '#000', size = '22px'}) => (
  <S.svg width={size} height={size} stroke={color} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="spinner_V8m1"><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle></g></S.svg>
);

export default Loading;
