import React, { useState, useLayoutEffect } from 'react';

const useBrowserScroll = () => {
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(Math.floor(window.scrollY));
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return { scroll };
};

export default useBrowserScroll;
