import React, { useState, useLayoutEffect } from 'react';

const useBrowserResize = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleSize = () => {
    setWidth(Math.floor(window.innerWidth));
    setHeight(Math.floor(window.innerHeight));
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, []);

  return { width, height };
};

export default useBrowserResize;
