import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_API;

export function getTradeData(params) {
  return axios({
    url: `${baseURL}/api/getTradeData`,
    method: 'get',
    params,
  });
}

export function mintIncubator(params) {
  return axios({
    url: `${baseURL}/api/mintIncubator`,
    method: 'post',
    params,
  });
}

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 60000,
})

console.log('这是service',service)

service.interceptors.request.use(
  config => {
      // console.log(config)
      // const token = localStorage.getItem('token')
      // if(token)config.headers["Authorization"] = 'Bearer ' + token
      // config.headers['Cache-Control'] = 'no-cache';
      return config
  },
  error => {
      console.log('出错---',error);
      Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    return response.data
      // console.log('response---',response)
      // const res= response.data
      // if(res.code === 0){
      //     return response.data
      // }else{
      //     localStorage.removeItem('address');
      //     localStorage.removeItem('user');
      //     return Promise.reject(res)
      // }
      
  },
  error => {
      console.log('响应出错---',error)
      return Promise.reject(error)
  }
)

export default service;