import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  transform: translateY(-50%);
  left: ${({ isLeft }) => (isLeft ? '50px' : 'unset')};
  right: ${({ isLeft }) => (isLeft ? 'unset' : '50px')};
  top: ${({ align }) => (align === 'top' ? '20%' : align === 'center' ? '40%' : '65%')};
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Circle = styled.a`
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  border: 1px solid ${(props) => props.theme.main};
  background: ${(props) =>
    props.selected
      ? 'linear-gradient(8.48deg, #02FFFF 15.03%, rgba(2, 255, 255, 0) 92.61%), black'
      : 'black'};
  position: absolute;
  top: ${({ top }) => top};
  z-index: 2;
  transistion: background 0.3s;
`;

export const Line = styled.div`
  border: 1px dashed ${(props) => props.theme.main};
  height: ${({ height }) => height};
  position: absolute;
  left: 7px;
  top: 7.5px;
  z-index: 1;
`;
